import PanelLayout from "../../../layout/panel";
import Style from '../../../assets/css/panel.module.css';
import FundsTabs from "../../../components/funds/tabs";
import {getAccountApi} from "../../../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import WalletList from "../../../components/funds/wallet";
import {useTranslation} from "react-i18next";

const Wallets = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [loading, setLoading] = useState(false);
    const [wallet, setWallet] = useState({});
    const [IBWallet, setIBWallet] = useState({});

    useEffect(()=>{
        if(token){
            getAccounts();
        }
        document.title = `${translate('wingo')} - ${translate('menu_funds_wallets')}`;
    },[]);

    const getAccounts = async () => {
        setLoading(true)
        const data = {
            category: null,
            scope: null
        }

        await getAccountApi(data, token).then((result)=>{

            const wallet = result.data.find((x)=> x.typeId === 1);
            const ibWallet = result.data.find((x)=> x.typeId === 2);

            setWallet(wallet)
            setIBWallet(ibWallet)
            setLoading(false)

        }).catch((error)=> {
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_funds')}</h1>
                <FundsTabs/>
                <h2>{translate('menu_funds_wallets')}</h2>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding} ${Style.WalletList}`}>
                    <ul className="list-unstyled m-0 p-0">
                        <WalletList wallet={wallet} loading={loading} type={1}/>
                        <WalletList wallet={IBWallet} loading={loading} type={2}/>
                    </ul>
                </div>

            </div>
        </PanelLayout>
    )
}

export  default Wallets;