import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import AccountsTabs from "../../../components/accounts/tabs";
import {Skeleton} from "antd";
import DemoAccounts from "../../../components/accounts/demo";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAccountApi} from "../../../api/api";
import {useTranslation} from "react-i18next";
import Guides from "../../../components/guides";

const AccountDemo = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const user = useSelector((state) => state.auth?.user || null);
    const { t } = useTranslation();
    const translate = t;
    const [demoAccounts, setDemoAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emptyDemo, setEmptyDemo] = useState(false);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        if(token){
            getAccounts()
        }
        document.title = `${translate('wingo')} - ${translate('demo_accounts')}`;
    },[]);

    const getAccounts = async () => {
        setLoading(true)
        const data = {
            category: null,
            scope: null
        }
        await getAccountApi(data, token).then((result)=>{
            const demoAccountsArray = [];
            let j = 1;

            result.data.map(item => {
                if (item.typeId === 7 || item.typeId === 14 || item.typeId === 15 || item.typeId === 16) {
                    j++;
                    demoAccountsArray.push({ ...item, key: j });
                }
            });

            setDemoAccounts(demoAccountsArray)
            setLoading(false)

            if(!demoAccountsArray.length){
                setEmptyDemo(true)
            }

        }).catch((error)=> {
            console.log(error)
        })
    }

    const columns = [
        {
            key: 'login',
            title: translate('accounts_login'),
            dataIndex: 'login'
        },
        {
            key: 'type',
            title: translate('account_type'),
            dataIndex: 'type',
            render: (type) => type.title
        },
        {
            key: 'type',
            title: translate('menu_platform'),
            dataIndex: 'type',
            render: (type) => type.platform
        },
        {
            key: 'currency',
            title: translate('funds_currency'),
            dataIndex: 'currency'
        },
        {
            key: 'leverage',
            title: translate('funds_leverage'),
            dataIndex: 'leverage'
        },

        {
            key: 'balance',
            title: translate('funds_balance'),
            dataIndex: 'balance'
        }
    ]

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('accounts')}</h1>
                <Guides type="changeDemoPassword" locale={lang}/>
                <AccountsTabs/>

                <h2>{translate('demo_accounts')}</h2>

                <div className={`${Style.PanelBox} ${loading ? Style.PanelBoxPadding : ``}`}>
                    {demoAccounts?.length ? (
                        <DemoAccounts demoAccounts={demoAccounts} user={user} loading={loading} columns={columns}/>
                    ) : (
                        emptyDemo ?
                            <div style={{padding: "20px"}}>
                                {translate('no_demo_accounts')}
                            </div>
                            : <Skeleton active />
                    )}
                </div>

            </div>
        </PanelLayout>
    )
}

export default AccountDemo;