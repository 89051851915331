import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import {Button, Col, Input, Row, Select} from "antd";
import {useEffect, useState} from "react";
import Verified from "../../assets/img/icon/check-verified.svg";
import {useTranslation} from "react-i18next";
import {getAccountApi, getApplicationsApi, uploadApplicationApi} from "../../api/api";
import {useSelector} from "react-redux";
import {countriesCode} from "../../api/data";
import Guides from "../../components/guides";

const IB = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [ibReg, setIbReg] = useState(false);
    const [ibRequest, setIbRequest] = useState({});
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('request_ib')}`;
        getAccounts();
        getApplications();
    },[]);

    const getAccounts = async () => {
        const data = {
            category: null,
            scope: "wallet"
        }
        await getAccountApi(data, token).then((result)=>{
            setIbRequest({loginSid: result.data[0]?.loginSid})
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getApplications = async () => {
        await getApplicationsApi(token).then(()=>{

        }).catch((error)=>{
            console.log(error)
        })
    }

    const uploadApplication = async () => {
        const data = {
            "sections": [
                {
                    "fields": [
                        {
                            "key": "Target country for business",
                            "value": ibRequest?.country
                        },
                        {
                            "key": "Select IB Account currency",
                            "value": "USD"
                        }
                    ],
                }
            ],
            "configId": 20,
            "loginSid": ibRequest?.loginSid
        }

        await uploadApplicationApi(data, token).then(()=>{
            setIbReg(true)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('request_ib')}</h1>
                <Guides type="requestIB" locale={lang}/>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('ib_app')}</h3>

                    {ibReg ?
                        <div className={Style.DepositSuccess}>
                            <div>
                                <img width={87} height={87} src={Verified}/>
                            </div>
                            <strong>{translate('ib_app_req')}</strong>
                            <br/>
                            <br/>
                            <p>{translate('ib_thanks')}</p>
                            <p>{translate('ib_req_message')}</p>
                        </div>
                        : <div>
                        <Row gutter={120} className={Style.IBForm} style={{marginTop: "35px"}}>
                            <Col md={12}>
                                <div className="d-flex">
                                    <label>Country</label>
                                    <Select
                                        showSearch={true}
                                        className="w-100"
                                        placeholder={translate('ib_select_country')}
                                        options={countriesCode}
                                        onChange={(_, _value)=> setIbRequest({...ibRequest, country: _value.iso})}
                                        filterOption={filterOption}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="d-flex">
                                    <label>{translate('funds_currency')}</label>
                                    <Select
                                        className="w-100"
                                        placeholder={translate('ib_currency')}
                                        options={[{key: "USD", label: "USD"}]}
                                        value="USD"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className="justify-content-end d-flex" style={{marginTop: "100px"}}>
                            <Button onClick={()=>uploadApplication()} className="dark-green-button white-color" shape="round">{translate('continue')}</Button>
                        </div>
                    </div>}
                </div>
            </div>
        </PanelLayout>
    )
}

export default IB;