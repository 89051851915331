import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import {Button, message, Timeline, Upload} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {createTicketCommentApi, getTicketDataApi} from "../../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import UserIcon from "../../assets/img/icon/help/user-circle.svg";
import AuthorIcon from "../../assets/img/icon/help/author-circle.png";
import TextArea from "antd/es/input/TextArea";
import {InboxOutlined} from "@ant-design/icons";
import {Base_Url} from "../../api/config";
import { PaperClipOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
const { Dragger } = Upload;

const TicketView = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const params = useParams();
    const navigate = useNavigate();
    const [ticket, setTicket] = useState({});
    const [timeline, setTimeline] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reply, setReply] = useState({});
    const [attachment, setAttachment] = useState([]);
    const {id} = params;

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('ticket')} ${id}`;
        getTicketData()
    },[]);

    const getTicketData = async () => {
        await getTicketDataApi(id, token).then((result)=>{

            const timeline = [];
            result.data.comments.map((Comment)=>(
                timeline.push({
                    children: Comment.author === "Support" ?
                        <div className={Style.TicketAuthor}>
                            <div className="d-flex align-items-center">
                                <img src={AuthorIcon}/>
                                <span className={Style.TicketAuthorName}>{Comment.author}</span>
                            </div>
                            <div className={Style.TicketText}>
                                <p style={{whiteSpace: "pre-line"}}>{Comment.text}</p>
                            </div>
                        </div> :
                        <div className={Style.TicketUser}>
                            <div className="d-flex align-items-center">
                                <img src={UserIcon}/>
                                <div>
                                    <span className={Style.TicketAuthorName}>{Comment.author}</span>
                                    <time className={Style.TicketTime}>{Comment.createdAt}</time>
                                </div>
                            </div>
                            <div className={Style.TicketText}>
                                <p style={{whiteSpace: "pre-line"}}>{Comment.text}</p>

                                {Comment?.attachments?.length ?
                                <div className={Style.TicketAttachment}>
                                    <strong>Attachments:</strong>
                                    <div>
                                        {Comment?.attachments?.map((Attach, Index)=>{
                                            return (
                                                <Link className={Style.TicketAttachmentList} target="_blank" key={Index} to={`${Base_Url}${Attach.path}`}>
                                                    <PaperClipOutlined />
                                                    {Attach.name}
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </div> : null}
                            </div>
                        </div>,
                    dot: Comment.author === "Support" ?
                        <span className={Style.adminDot}><time>{Comment.createdAt}</time></span> :
                        <span className={Style.userDot}><time>{Comment.createdAt}</time></span>
                })
            ));

            const obj = {
                children: <ReplyTemplate data={result.data}/>,
                dot: <span className={Style.replyDot}></span>
            }
            timeline.push(obj)

            setTimeline(timeline)
            setTicket(result.data)
            setLoading(false)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const createTicketComment = async () => {
        setLoading(true)
        const data = {
            ...reply,
            ...(attachment.length && {attachments: attachment})
        }

        await createTicketCommentApi(data, token).then(()=>{
            setLoading(false)
            getTicketData();
            setReply(null)
        }).catch((error)=>{
            setLoading(false)
            message.error(error)
        })
    }

    const uploadProps = {
        name: 'file',
        multiple: true,
        customRequest(file){
            uploadFile(file)
        },
        onChange(file) {
            file.fileList.filter((x)=>x.status = "done")
        }
    }

    const uploadFile = async ({ file, onSuccess, onError }) => {
        await getBase64(file)
            .then(res => setAttachment(prevAttachment => [...prevAttachment, { name: file.name, file: res.split('base64,').pop() }]))
            .catch(err => console.log(err))
    }

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const ReplyTemplate = ({data}) => {
        return (
            <div className={Style.TicketReply}>
                <TextArea placeholder={translate('new_comment')} onChange={(e)=> setReply({...reply, text: e.target.value, ticket: data.id})}/>
                <div style={{marginTop: "20px"}}>
                    <Dragger
                        {...uploadProps}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{translate('upload_title')}</p>
                        <p className="ant-upload-hint">
                            {translate('upload_notes')}
                        </p>
                    </Dragger>
                </div>
            </div>
        )
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_open_tickets')}</h1>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{ticket?.title}</h3>

                    <div className={Style.PanelBoxTicket}>
                        <Timeline
                            pending={loading ? "!" : null}
                            className={Style.TicketTimeline}
                            items={timeline}
                        />

                        <div className="d-flex justify-content-end">
                            <Button onClick={()=>navigate("/helpdesk/open-tickets")} shape="round" style={dir === "ltr" ? {marginRight: "10px"} : {marginLeft: "10px"}}>{translate('back')}</Button>
                            <Button disabled={!reply?.text} loading={loading} onClick={()=> createTicketComment()} shape="round" className="dark-green-button white-color">{translate('submit')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </PanelLayout>
    )
}

export default TicketView;