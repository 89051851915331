/* Accounts */

export const accounts_types = "/client-api/accounts/types";
export const accounts = "/client-api/accounts";
export const accounts_loginSid = (loginSid) => `/client-api/accounts/${loginSid}`;
export const accounts_new = "/client-api/accounts/new";
export const accounts_change_password = "/client-api/accounts/change/password";
export const accounts_change_leverage = "/client-api/accounts/change/leverage";
export const accounts_trading_history = "/client-api/accounts/trading-history";
export const accounts_available_amount_for_withdrawal = (loginSid) => `/client-api/accounts/${loginSid}/available-amount-for-withdrawal`;
export const send_report = "/client-api/accounts/change/send-report";
export const swap_free_request_form = (loginSid) => `/client-api/accounts/${loginSid}/swap-free-request-form`;
export const swap_free_request = (loginSid) => `/client-api/accounts/${loginSid}/swap-free-request`;

/* Applications */

export const applications = "/client-api/applications";
export const applications_upload = "/client-api/applications/upload";
export const applications_configs = "/client-api/applications/configs";
export const applications_configs_id = (id) => `/client-api/applications/configs/${id}`;

/* Cashback Breakdown */

export const cashback_breakdown = "/client-api/cashback-breakdown";

/* Charts */

export const chart_name = (name) => `/client-api/chart/${name}`;
export const charts_info = "/client-api/charts-info";

/* Company Documents */

export const company_documents_for_accept = "/client-api/company-documents/for-accept";
export const company_documents_accepted = "/client-api/company-documents/accepted";
export const company_documents_all = "/client-api/company-documents/all";
export const company_documents_accept = "/client-api/company-documents/accept";
export const company_documents_id = (id) => `/client-api/company-documents/${id}`;

/* System */

export const systems_configuration = "/client-api/configuration";
export const systems_file = "/client-api/file";
export const systems_ping = "/client-api/ping";

/* Deposits */

export const deposit = "/client-api/deposit";
export const deposit_demo = "/client-api/deposit/demo";
export const payment_systems_deposit = "/client-api/payment-systems/deposit";
export const deposit_fees = "/client-api/deposit/fees";
export const payment_systems_deposit_loginSid = (loginSid) => `/client-api/payment-systems/deposit/${loginSid}`

/* Dict */

export const dict_currencies = "/client-api/dict/currencies";
export const dict_report_currency = "/client-api/dict/report-currency";
export const dict_languages = "/client-api/dict/languages";

/* Security */

/*export const login_direct = "/client-api/login/direct";*/
export const ping_send = "/client-api/pin/send";
export const login = "/client-api/login";
export const two_fa_check = "/client-api/2fa-check";
export const logout = "/client-api/logout";
export const forget_password = "/client-api/forgot-password";
export const forget_password_pin = (pin) => `/client-api/forgot-password/${pin}`;
export const social_login_url = "/client-api/social-login/url";
export const social_login_auth = "/client-api/social-login/auth";
export const social_login_connect = "/client-api/social-login/connect";
export const social_login_disconnect = (service) => `/client-api/social-login/disconnect/${service}`;

/* Documents */

export const documents = "/client-api/documents";
export const documents_id = (id) => `/client-api/documents/${id}`;
export const documents_upload = "/client-api/documents/upload";
export const documents_configs = "/client-api/documents/configs";

/* Downloads */

export const downloads = "/client-api/downloads";

/* Help Desk */

export const ticket_comments_all = "/client-api/help-desk/ticket-comments/all";
export const ticket_comments = "/client-api/help-desk/ticket-comments";
export const ticket_comments_id_put = (id) => `/client-api/help-desk/ticket-comments/{id}`;
export const ticket_comments_id_delete = (id) => `/client-api/help-desk/ticket-comments/{id}`;
export const tickets_open = "/client-api/help-desk/tickets/open";
export const tickets_closed = "/client-api/help-desk/tickets/closed";
export const all_tickets = "/client-api/help-desk/tickets/all";
export const ticket_categories = "/client-api/help-desk/ticket-categories";
export const ticket = "/client-api/help-desk/tickets";
export const unread_tickets_count = "/client-api/help-desk/unread-tickets-count";
export const tickets_id_get = (id) => `/client-api/help-desk/tickets/${id}`;
export const tickets_id_delete = (id) => `/client-api/help-desk/tickets/${id}`;
export const tickets_close_id = (id) => `/client-api/help-desk/tickets/${id}/close`;

/* Public */

export const marketing_public_link_detail_id = (id) => `/client-api/marketing/public/link-detail/${id}/`;
export const public_link_detail_id = (id) => `/client-api/ib/public/link-detail/${id}/`;

/* Payment Details */

export const payment_details_system_id = (paymentSystemId) => `/client-api/payment-details/${paymentSystemId}`;
export const payment_details = "/client-api/payment-details";
export const payment_details_id = (id) => `/client-api/payment-details/${id}`;
export const payment_details_upload = "/client-api/payment-details/upload";
export const payment_details_configs = "/client-api/payment-details/configs";

/* Profile */
export const profile_messages = "/client-api/profile/messages";
export const profile_messages_id = (id) => `/client-api/profile/messages/${id}`;
export const profile_messages_unread_count = "/client-api/profile/messages/unread-count";
export const profile_notification_preferences_options = "/client-api/profile/notification-preferences/options";
export const profile_change_notification_preferences = "/client-api/profile/change/notification-preferences";

export const profile_get = "/client-api/profile";
export const profile_put = "/client-api/profile";

export const profile_change_email = "/client-api/profile/change-email";
export const profile_change_phone = "/client-api/profile/change-phone";
export const profile_change_password = "/client-api/profile/change-password";
export const profile_contact_manager_details = "/client-api/profile/contact-manager-details";
export const profile_update_custom_fields = "/client-api/profile/update-custom-fields";
export const profile_change_language = "/client-api/profile/change-language";
export const profile_verify_email = "/client-api/profile/verify-email";
export const profile_verify_email_validate = (hash) => `/client-api/profile/verify-email/validate/${hash}`;

/* Two Factor */

export const profile_two_factor_qr_code = "/client-api/profile/two-factor/qr-code";
export const profile_two_factor_enable = "/client-api/profile/two-factor/enable";
export const profile_two_factor_disable = "/client-api/profile/two-factor";
export const profile_two_factor_clear_trusted = "/client-api/profile/two-factor/clear-trusted";
export const profile_two_factor_backup_codes = "/client-api/profile/two-factor/backup-codes";

/* Registration */

export const registration_get = "/client-api/registration";
export const registration_post = "/client-api/registration";
export const registration_put = "/client-api/registration";
export const registration_send_pin = "/client-api/registration/send-pin";
export const registration_confirmation = "/client-api/registration/confirmation";

/* TradingCalculator */

export const trading_calculator_get = "/client-api/trading-calculator/";
export const trading_calculator_post = "/client-api/trading-calculator/";

/* Transactions */

export const transactions_cancel = (id) => `/client-api/transactions/cancel/${id}`;
export const transactions_post = "/client-api/transactions";

/* Transfers */

export const transfers_post = "/client-api/transfers/";
export const transfers_check = "/client-api/transfers/check";
export const transfers_new = "/client-api/transfers/new";
export const transfers_any_new = "/client-api/transfers/any/new";

/* Withdrawals */

export const payout_post = "/client-api/payout";
export const withdrawal_detail_post = "/client-api/withdrawal/detail";
export const payment_systems_withdrawal_get = "/client-api/payment-systems/withdrawal";
export const payment_systems_withdrawal_loginSid_get = (loginSid) => `/client-api/payment-systems/withdrawal/${loginSid}`;

/* IB */

export const ib_banners_post = "/client-api/ib/banners";
export const ib_banners_campaigns_get = "/client-api/ib/banners/campaigns";
export const ib_banners_id_get_code_linkId_get = (id, linkId) => `/client-api/ib/banners/${id}/get-code/${linkId}`;
export const ib_referrals_post = "/client-api/ib/referrals";
export const ib_referrals_accounts_post = "/client-api/ib/referrals/accounts";
export const ib_commissions_post = "/client-api/ib/commissions";
export const ib_ib_transactions_post = "/client-api/ib/ib-transactions";
export const ib_links_get = "/client-api/ib/links";
export const ib_links_landing_pages_get = "/client-api/ib/links/landing-pages";
export const ib_links_new_post = "/client-api/ib/links/new";
export const ib_links_id_edit_post = (id) => `/client-api/ib/links/${id}/edit`;
export const ib_links_id_delete = (id) => `/client-api/ib/links/${id}`;
export const ib_public_link_detail_id_get = (id) => `/client-api/ib/public/link-detail/${id}/click`;
export const ib_reports_other_network_commissions_post = "/client-api/ib/reports/other-network-commissions";
export const ib_reports_accounts_commissions_post = "/client-api/ib/reports/accounts-commissions";
export const ib_reports_clients_commissions_post = "/client-api/ib/reports/clients-commissions";
export const ib_reports_cpa_payments_post = "/client-api/ib/reports/cpa-payments";
export const ib_tree_post = "/client-api/ib/tree";
export const ib_referral_breakdown_id_post = (id) => `/client-api/ib/referral-breakdown/${id}`;
export const ib_performance_dashboard_get = "/client-api/ib/performance-dashboard";

/* KYC */

export const kyc_global_pass_screening_token = "/client-api/kyc/global-pass/screening-token";

/* Widgets */

export const widgets = "/client-api/widgets";
export const widgets_alias = (alias) => `/client-api/widgets/${alias}`;