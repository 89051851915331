import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import ToolsTabs from "../../components/tools/tabs";
import {Button, Col, Row, Select} from "antd";
import Input from "antd/es/input/Input";
import {useEffect, useState} from "react";
import {doTradeCalculationApi, getTradingCalculatorDataApi} from "../../api/api";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Calculator = () => {

    const state = useSelector((state)=>state);
    const token = state.auth.token;
    const { t } = useTranslation();
    const translate = t;
    const [Calculator, setCalculator] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [instruments, setInstruments] = useState([]);
    const [leverages, setLeverages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [result, setResult] = useState({});

    useEffect(()=>{
        if(token){
            getTradingCalculatorData()
        }
        document.title = `${translate('wingo')} - ${translate('menu_trading_calculator')}`;
    },[])

    const getTradingCalculatorData = async () => {
        setLoading(true);
        await getTradingCalculatorDataApi(token).then((result)=>{

            const accountCurrencies = result.data.accountCurrencies.filter((x)=> (x.label = x.text));
            const leveragesData = result.data.leverages.filter((x)=> (x.label = x.text));
            const instrumentsData = result.data.instruments[""].filter((x)=> (x.label = x.text));

            setCurrencies(accountCurrencies)
            setLeverages(leveragesData)
            setInstruments(instrumentsData)

            setLoading(false);

        }).catch((error)=>{
            console.log(error)
        })
    }

    const doTradeCalculation = async () => {
        setButtonLoading(true)
        await doTradeCalculationApi(Calculator, token).then((result)=>{
            setResult(result.data)
            setButtonLoading(false)
        })
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_tools')}</h1>
                <ToolsTabs />

                <h2>{translate('menu_trading_calculator')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>

                    <div className={`${Style.DepositMethod} ${Style.DepositNotes}`} style={{ marginTop: "0px", marginBottom: "40px" }}>
                        <p>{translate('account_trading_calculator_message')}</p>
                    </div>

                    <Row gutter={55} className={Style.DepositMethodForm}>
                        <Col md={12} style={{ paddingBottom: "30px" }}>
                            <div className={`${Style.DepositMethods} d-md-flex`}>
                                <label className="text-nowrap">{translate('my_tools_volume')}</label>
                                <Input
                                    className="deposit-select"
                                    value={Calculator?.volume}
                                    placeholder="0.00"
                                    onChange={(e) => setCalculator({ ...Calculator, volume: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={12} style={{ paddingBottom: "30px" }} className="ant-col-full">
                            <div className={`${Style.DepositMethods} d-md-flex`}>
                                <label className="text-nowrap">{translate('funds_leverage')}</label>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder={translate('select_option')}
                                    value={Calculator?.leverage}
                                    loading={loading}
                                    disabled={loading}
                                    className="w-100 deposit-select"
                                    options={leverages}
                                    filterOption={filterOption}
                                    onChange={(choice) => setCalculator({ ...Calculator, leverage: choice })}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={55} className={Style.DepositMethodForm}>
                        <Col style={{ paddingBottom: "30px" }} md={12} className="ant-col-full">
                            <div className={`${Style.DepositMethods} d-md-flex`}>
                                <label className="text-nowrap">{translate('my_tools_instruments')}</label>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder={translate('select_option')}
                                    value={Calculator?.instrument}
                                    className="w-100 deposit-select"
                                    disabled={loading}
                                    loading={loading}
                                    options={instruments}
                                    filterOption={filterOption}
                                    onChange={(choice) => setCalculator({ ...Calculator, instrument: choice })}
                                />
                            </div>
                        </Col>
                        <Col md={12} className="ant-col-full">
                            <div className={`${Style.DepositMethods} d-md-flex`}>
                                <label className="text-nowrap">{translate('my_tools_account_currency')}</label>
                                <Select
                                    placeholder={translate('select_option')}
                                    showSearch
                                    optionFilterProp="children"
                                    value={Calculator?.accountCurrency}
                                    loading={loading}
                                    disabled={loading}
                                    className="w-100 deposit-select"
                                    options={currencies}
                                    filterOption={filterOption}
                                    onChange={(choice) => setCalculator({ ...Calculator, accountCurrency: choice })}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-end" style={{ marginTop: "30px" }}>
                        <Button
                            size="large"
                            loading={buttonLoading}
                            shape="round"
                            className="dark-green-button white-color"
                            disabled={!Calculator?.volume || !Calculator?.leverage || !Calculator?.instrument || !Calculator?.accountCurrency}
                            onClick={() => doTradeCalculation()}
                        >
                            {translate('calculate')}
                        </Button>
                    </div>

                    {Object.entries(result).length ? (
                        <div className={Style.ResultBox}>
                            <h5>{translate('results')}</h5>
                            <Row>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_contract_size')}</span>
                                    <bdi>{result?.contractSize}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_const_of_point')}</span>
                                    <bdi>{result?.price}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_tick')}</span>
                                    <bdi>{result?.tick}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_margin')}</span>
                                    <bdi>{result?.margin}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_spread')}</span>
                                    <bdi>{result?.spread}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_price_ask')}</span>
                                    <bdi>{result?.priceAsk}</bdi>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "15px" }}>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_spread_in_currency')}</span>
                                    <bdi>{result?.spreadCurrency}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>Price BID</span>
                                    <bdi>{result?.priceBid}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_price_bid')}</span>
                                    <bdi>{result?.swapLong}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_swap_long_in_currency')}</span>
                                    <bdi>{result?.swapLongCurrency}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_swap_short')}</span>
                                    <bdi>{result?.swapShort}</bdi>
                                </Col>
                                <Col xl={4} md={8} className="ant-col-full">
                                    <span>{translate('calculator_swap_short_in_currency')}</span>
                                    <bdi>{result?.swapShortCurrency}</bdi>
                                </Col>
                            </Row>
                        </div>
                    ) : null}

                </div>
            </div>
        </PanelLayout>
    )
}

export default Calculator;