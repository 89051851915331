import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import FundsTabs from "../../../components/funds/tabs";
import {Col, Row, Select, Table, Tag, Upload, Button, message} from "antd";
import {useEffect, useState} from "react";
import {
    deletePaymentDetailByIdApi,
    getPaymentDetailsApi,
    getPaymentDetailsConfigsApi,
    uploadPaymentDetailApi
} from "../../../api/api";
import {useSelector} from "react-redux";
import Input from "antd/es/input/Input";
import { UploadOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../../layout/components/windowSize";
const { Dragger } = Upload;

const PaymentDetails = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [uploadData, setUploadData] = useState({});
    const [uploadedData, setUploadedData] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const [address, setAddress] = useState({});
    const [currency, setCurrency] = useState({});
    const [file, setFile] = useState({});
    const [walletId, setWalletId] = useState({});
    const [attachment, setAttachment] = useState([]);
    const {device} = useWindowSize();

    useEffect(()=>{
        if(token){
            getPaymentDetailsConfigs();
            getPaymentDetails();
        }
        document.title = `${translate('wingo')} - ${translate('menu_funds_payment_details')}`;
    },[]);

    useEffect(()=>{
        if(uploadData.configId){
            const find = options.find((x)=>x.id === uploadData.configId);

            if(uploadData.configId === 24){
                const Obj = find.config.Currency.choices.map((x) => ({
                    key: x,
                    value: x,
                    label: x
                }));

                setCurrencies(Obj)
            }

        }
    },[uploadData])

    const getPaymentDetailsConfigs = async () => {
        await getPaymentDetailsConfigsApi(token).then((result)=>{

            result.data.filter((x)=> (x.label = x.title, x.value = x.id))
            setOptions(result.data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getPaymentDetails = async () => {
        setLoading(true)
        await getPaymentDetailsApi(token).then((result)=>{
            const data = result.data.filter((x)=>x.key = x.id)
            setUploadedData(data)
            setLoading(false)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const deletePaymentDetailById = async (id) => {
        await deletePaymentDetailByIdApi(id, token).then(()=>{
            message.success(translate('error_removed'))
            getPaymentDetails();
        }).catch((error)=>{
            console.log(error)
        })
    }

    const uploadPaymentDetail = async () => {
        setButtonLoading(true)

        const array = [];

        if(Object.keys(file).length){
            array.push(file)
        }

        if(Object.keys(address).length){
            array.push(address)
        }

        if(Object.keys(currency).length){
            array.push(currency)
        }

        if(Object.keys(walletId).length){
            array.push(walletId)
        }

        if(attachment.length){
            const att = {
                key: "Screenshot Showing Your Address",
                value: attachment
            }
            array.push(att)
        }

        const data = {
            ...uploadData,
            data: array
        }

        await uploadPaymentDetailApi(data, token).then(()=>{
            message.success("Successfully added!")
            setButtonLoading(false)
            getPaymentDetails();
            setUploadedData([])
        }).catch((error)=>{
            setButtonLoading(false)
            console.log(error)
        })
    }

    const columns = [
        {
            key: 1,
            dataIndex: 'title',
            title: translate('title'),
            render: (_, _value) => <span>{_value?.uploadConfig?.title}</span>
        },
        {
            key: 2,
            dataIndex: 'details',
            title: translate('details'),
            render: (_, _value) => <span>{_value?.number}</span>
        },
        {
            key: 3,
            dataIndex: 'status',
            title: translate('status'),
            render: (status) => status === "approved" ? <Tag style={{borderRadius: "250px", padding: "8px 15px"}} color="green">{status}</Tag> : <Tag style={{borderRadius: "250px", padding: "8px 15px"}} color="red">{status}</Tag>
        },
        {
            key: 4,
            dataIndex: 'tools',
            title: '',
            render: (_,_value) => <Button onClick={()=>deletePaymentDetailById(_value.id)} shape="round" type="primary" danger>{translate('remove')}</Button>
        }
    ];

    const uploadProps = {
        name: 'file',
        multiple: true,
        customRequest(file){
            uploadFile(file)
        },
        onChange(file) {
            file.fileList.filter((x)=>x.status = "done")
        }
    }

    const uploadFile = async ({ file, onSuccess, onError }) => {
        await getBase64(file)
            .then(res => setAttachment(prevAttachment => [...prevAttachment, { name: file.name, file: res.split('base64,').pop() }]))
            .catch(err => console.log(err))
    }

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_funds')}</h1>
                <FundsTabs/>
                <h2>{translate('menu_funds_payment_details')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding} ${Style.PanelBoxDetails}`}>
                    <h6>+ {translate('payment_details_upload')}</h6>
                    <Row gutter={35} style={{ marginTop: "20px" }}>
                        <Col md={12} className="ant-col-full">
                            <div className="d-md-flex align-items-center">
                                <label className="text-nowrap">{translate('payment_details_want_upload')}</label>
                                <Select
                                    loading={loading}
                                    disabled={loading}
                                    placeholder={translate('select_option')}
                                    style={dir === "ltr" && device !== "mobile" ? { marginLeft: "15px" } : dir === "rtl" && device !== "mobile" ? { marginRight: "15px"} : null}
                                    className="w-100 deposit-select"
                                    options={options}
                                    onChange={(choice) => setUploadData({ ...uploadData, configId: choice })}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            {uploadData?.configId === 24 ?
                                <>
                                    <div className={`${Style.DepositMethodForm} d-flex align-items-center`}>
                                        <label className="text-nowrap">{translate('funds_crypto_address')}</label>
                                        <Input className="deposit-select" style={dir === "ltr" ? { marginLeft: "15px" } : { marginRight: "15px"}} onChange={(e) => setAddress({ key: "Crypto Address", value: e.target.value })} />
                                    </div>
                                    <div className={`${Style.DepositMethodForm} d-flex align-items-center`} style={device !== "mobile" ? {marginTop: "30px"} : null}>
                                        <label className="text-nowrap">{translate('funds_currency')}</label>
                                        <Select
                                            placeholder={translate('select_option')}
                                            className="w-100 deposit-select"
                                            style={dir === "ltr" ? { marginLeft: "15px" } : { marginRight: "15px"}}
                                            onChange={(choice) => setCurrency({ key: "Currency", value: choice })}
                                            options={currencies}
                                        />
                                    </div>
                                </>
                                : uploadData?.configId === 23 ?
                                    <>
                                        <div className={`${Style.DepositMethodForm} d-flex align-items-center`}>
                                            <label className="text-nowrap">{translate('funds_walled_id')}</label>
                                            <Input className="w-100 deposit-select" style={dir === "ltr" ? { marginLeft: "15px" } : { marginRight: "15px"}} onChange={(e) => setWalletId({ key: "Wallet Id", value: e.target.value })} />
                                        </div>
                                    </>
                                    : uploadData?.configId === 26 ?
                                        <>
                                            <div className={`${Style.DepositMethodForm} d-flex align-items-center`}>
                                                <label className="text-nowrap">{translate('withdraw_address')}</label>
                                                <Input className="w-100 deposit-select" style={dir === "ltr" ? { marginLeft: "15px" } : { marginRight: "15px"}} onChange={(e) => setWalletId({ key: "withdrawalAddress", value: e.target.value })} />
                                            </div>
                                        </>
                                        : null
                            }
                        </Col>
                        <Col md={24}>
                            <div className="d-flex justify-content-end" style={{marginTop: "20px"}}>
                                <Button size="large" loading={buttonLoading} shape="round" disabled={!uploadData?.configId} onClick={() => uploadPaymentDetail()} className="dark-green-button white-color">{translate('submit')}</Button>
                            </div>
                        </Col>
                        {/*{uploadData?.configId === 24 ?
                            <>
                                <Col md={12}>

                                    <div className={Style.PaymentDetailsUpload}>
                                        <label>{translate('funds_screenshot')}</label>
                                        <Dragger
                                            {...uploadProps}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <UploadOutlined />
                                            </p>
                                            <p className="ant-upload-hint">
                                                {translate('upload_title')}
                                            </p>
                                        </Dragger>
                                        <small>{translate('upload_notes')}</small>
                                    </div>
                                </Col>
                            </>
                            : null}*/}
                    </Row>

                    <h4 style={{marginTop: "50px"}}>{translate('uploaded_payment_details')}</h4>
                    {uploadedData ?
                        <Table
                            className={Style.LinksTable
                        }
                            loading={loading}
                            dataSource={uploadedData}
                            columns={columns}
                            pagination={false}
                            scroll={{x: 500}}
                        />
                        : <span>{translate('nothing_upload')}</span>}
                </div>

            </div>
        </PanelLayout>
    )
}

export default PaymentDetails;