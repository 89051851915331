import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import AccountsTabs from "../../../components/accounts/tabs";
import {Skeleton} from "antd";
import LiveAccounts from "../../../components/accounts/live";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAccountApi} from "../../../api/api";
import {useTranslation} from "react-i18next";
import Guides from "../../../components/guides";

const LiveDemo = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const user = useSelector((state) => state.auth?.user || null);
    const { t } = useTranslation();
    const translate = t;
    const [liveAccounts, setLiveAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emptyLive, setEmptyLive] = useState(false);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        if(token){
            getAccounts()
        }
        document.title = `${translate('wingo')} - ${translate('live_accounts')}`;
    },[]);

    const getAccounts = async () => {
        setLoading(true)
        const data = {
            category: null,
            scope: null
        }
        await getAccountApi(data, token).then((result)=>{
            const liveAccountsArray = [];
            let j = 1;

            result.data.map(item => {
                if(item.typeId !== 7
                    && item.typeId !== 14
                    && item.typeId !== 15
                    && item.typeId !== 16
                    && item.typeId !== 1
                    && item.typeId !== 2
                ) {
                    j++;
                    liveAccountsArray.push({ ...item, key: j });
                }
            });

            setLiveAccounts(liveAccountsArray)
            setLoading(false)
            if(!liveAccountsArray.length){
                setEmptyLive(true)
            }
        }).catch((error)=> {
            console.log(error)
        })
    }

    const columns = [
        {
            key: 'login',
            title: translate('accounts_login'),
            dataIndex: 'login'
        },
        {
            key: 'type',
            title: translate('account_type'),
            dataIndex: 'type',
            render: (type) => type.title
        },
        {
            key: 'type',
            title: translate('menu_platform'),
            dataIndex: 'type',
            render: (type) => type.platform
        },
        {
            key: 'currency',
            title: translate('funds_currency'),
            dataIndex: 'currency'
        },
        {
            key: 'leverage',
            title: translate('funds_leverage'),
            dataIndex: 'leverage'
        },

        {
            key: 'balance',
            title: translate('funds_balance'),
            dataIndex: 'balance'
        }
    ]

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_accounts')}</h1>
                <Guides type="changeLivePassword" locale={lang}/>

                <AccountsTabs/>

                <h2>{translate('live_accounts')}</h2>

                <div className={`${Style.PanelBox} ${loading ? Style.PanelBoxPadding : ``}`}>
                    {liveAccounts?.length ? (
                        <LiveAccounts liveAccounts={liveAccounts} user={user} loading={loading} columns={columns}/>
                    ) : (
                        emptyLive ?
                            <div style={{padding: "20px"}}>
                                {translate('no_live_accounts')}
                            </div>
                            : <Skeleton active />
                    )}
                </div>
            </div>
        </PanelLayout>
    )
}

export default LiveDemo;