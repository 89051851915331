import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/ib/tabs";
import {createIbBannersApi} from "../../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Button, Image, Input, Space, Table} from "antd";
import {SearchOutlined, ArrowDownOutlined} from "@ant-design/icons";
import {Base_Url} from "../../../api/config";
import { saveAs } from 'file-saver'
import {useTranslation} from "react-i18next";

const Banners = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    useEffect(()=>{
        createIbBanners();
        document.title = `${translate('wingo')} - ${translate('menu_partnership_banners')}`;
    },[])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        sorter: (a, b) => {
            const valueA = a[dataIndex];
            const valueB = b[dataIndex];

            if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
                return parseFloat(valueA) - parseFloat(valueB);
            } else {
                return valueA && valueA.localeCompare(valueB);
            }
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                <span>{text}</span>
            ),
    });

    const createIbBanners = async () => {
        setLoading(true)
        const data = {

        }
        await createIbBannersApi(data, token).then((result)=>{
            const columnsWithSearchProps = result.data.columns
                .filter((x) => x.dataIndex = x.key)
                .map((column) => ({
                    ...column,
                    ...getColumnSearchProps(column.key, column.title),
                }));

            const obj = {
                key: 'download',
                dataIndex: 'download',
                title: 'Download'
            }
            columnsWithSearchProps.push(obj)
            setColumns(columnsWithSearchProps)

            const apiData = result.data.rows;
            apiData.forEach((x)=>{
                x.data.push({key: 'download', value: 'download'})
            })

            const antDesignTableData = apiData.map((item, index) => {
                const dataObj = {};
                let code = null;

                item.data.forEach((entry) => {
                    if(entry.key.includes("preview")){
                        code = entry.value
                    }
                    dataObj[entry.key] = entry.key.includes("preview") ?
                        <Image width={150} src={Base_Url + entry.value}/>
                        : entry.key == "download" ? <Button className="dark-green-button-border dark-green-color" shape="round" onClick={()=> download(code)} icon={<ArrowDownOutlined/>}>{translate('download')}</Button>
                        : entry.value
                });
                dataObj.key = index.toString();
                return dataObj;
            });

            setRows(antDesignTableData);
            setLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const download = (d) => {
        saveAs(Base_Url + d, Base_Url + d)
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_partnership')}</h1>
                <ProfileTabs/>
                <h2>{translate('menu_partnership_banners')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <div>
                        <Table
                            className={Style.LinksTable}
                            scroll={{x: 560}}
                            loading={loading}
                            columns={columns}
                            dataSource={rows}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </PanelLayout>
    )
}

export default Banners;