import Style from "../../assets/css/panel.module.css";
import {Button, Table} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Overview = ({demoAccounts, liveAccounts, loading}) => {

    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();

    const columns = [
        {
            key: 'login',
            dataIndex: 'login',
            title: translate('funds_account'),
            render: (login, _value) =>
                <div>
                    <span className={Style.AccountName} style={{marginRight: "20px"}}>{login}</span>
                    <span className={Style.OverviewTag}>{_value.type.title} {_value.type.platform}</span>
                </div>
        },
        {
            key: 'balance',
            dataIndex: 'balance',
            title: translate('funds_balance'),
            render: (balance, _value) => <span>{balance} <bdi>{_value.currency}</bdi></span>
        },
        {
            key: 'leverage',
            dataIndex: 'leverage',
            title: translate('funds_leverage'),
            render: (leverage)=> <span>{leverage ? leverage : `-`}</span>
        },
        {
            key: 'tools',
            dataIndex: 'tools',
            title: '',
            render: (_,_value) =>
                _value.type.id !== 7 ?
                    <Button onClick={()=>navigate(`/funds/transfer/${_value?.loginSid}`)} className="white-button black-color" shape="round"><strong>{translate('funds_transfer')}</strong></Button>
                    : <Button onClick={()=>navigate(`/accounts/show/${_value?.loginSid}`)} className="white-button black-color" shape="round"><strong>{translate('view')}</strong></Button>
        }
    ]

    return (
        <>
            <h6>{translate('menu_accounts_overview')}</h6>
            <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                <div className={`${Style.OverviewTable} ${Style.PanelBoxWrapper}`}>
                    <h4><i>{translate('demo')}</i> {translate('accounts')}</h4>
                    <Table
                        dataSource={demoAccounts}
                        columns={columns}
                        pagination={false}
                        align={'center'}
                        loading={loading}
                        scroll={{
                            y: 200,
                            x: 560
                        }}
                    />
                </div>

                <div className={`${Style.OverviewTable} ${Style.PanelBoxWrapper}`}>
                    <h4><i>{translate('live')}</i> {translate('accounts')}</h4>
                    <Table
                        dataSource={liveAccounts}
                        columns={columns}
                        pagination={false}
                        align={'center'}
                        loading={loading}
                        scroll={{
                            y: 200,
                            x: 560
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default Overview;