import React from 'react';
import { useSelector } from 'react-redux';
import { ConfigProvider, theme } from 'antd';
import enGB from 'antd/lib/locale/en_GB';
import faIR from 'antd/lib/locale/fa_IR';
import arAR from 'antd/lib/locale/ar_EG';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_BE';
const { defaultAlgorithm, darkAlgorithm } = theme;

const AntProvider = ({ children }) => {

    const language = useSelector((state) => state.auth?.setting?.lang || "en");
    const theme = useSelector((state) => state.auth?.dark || false);

    const getAntLang = () => {
        switch (language) {
            case 'en':
                return enGB;
            case 'fa':
                return faIR;
            case 'ar':
                return arAR;
            case 'es':
                return esES;
            case 'fr':
                return frFR;
            default:
                return enGB;
        }
    }

    const getAntDir = () => {
        switch (language) {
            case 'en':
                return "ltr";
            case 'fa':
                return "rtl";
            case 'ar':
                return "rtl";
            default:
                return "ltr";
        }
    }

    const getFont = () => {
        switch (language) {
            case 'en':
                return "Rubik"
            case 'fa':
                return "IRANSans"
            default:
                return "Rubik"
        }
    }

    return (
        <ConfigProvider
            direction={getAntDir()}
            locale={getAntLang()}
            theme={{
                algorithm: theme ? darkAlgorithm : defaultAlgorithm,
                token:
                    {
                        fontFamily: getFont(),
                        colorPrimary: "#00a066"
                    }
            }}
        >
            {children}
        </ConfigProvider>
    )
}

export default AntProvider;