import {Tabs} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const FundsTabs = () => {

    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [activeKey, setActiveKey] = useState(null);

    useEffect(()=>{
        urlToKey(pathname)
    },[pathname]);

    const urlToKey = (path) => {
        if (pathname.includes(path)) {
            const find = items.find((x) => x.url.indexOf(path) !== -1);

            if(find){
                setActiveKey(find.key)
            } else {
                setActiveKey(4)
            }
        }
    }

    const onChange = (key) => {
        const find = items.find((x) => x.key === key);
        navigate(find.url)
        setActiveKey(key)
    }

    const items = [
        {
            key: 1,
            label: translate('menu_profile'),
            url: '/profile',
        },
        {
            key: 2,
            label: translate('menu_verification'),
            url: '/profile/verification',
        },
        {
            key: 3,
            label: translate('menu_my_agreements'),
            url: '/profile/agreements',
        },
        {
            key: 4,
            label: translate('menu_messages'),
            url: '/profile/messages',
        },
        {
            key: 5,
            label: translate('menu_two_factor_authentication'),
            url: '/profile/two-factor',
        }
    ];

    return (
        <>
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
        </>
    )
}

export default FundsTabs;