import Style from '../assets/css/panel.module.css';
import Sidebar from "./components/panel/sidebar";
import Header from "./components/panel/header";
import {useSelector} from "react-redux";
import LiveChat from 'react-livechat';
import React from "react";

const PanelLayout = ({children}) => {

    const dir = useSelector((state) => state.auth?.setting?.dir || null);
    const theme = useSelector((state) => state.auth?.dark || false);

    return (
        <section className={`${Style.PanelContainer} ${dir === "rtl" ? `${Style.RtlStyle} rtl` : ``} ${theme ? `${Style.DarkMode} dark` : ``}`}>
            <Sidebar/>
            <main className={Style.MainContent}>
                <Header/>
                <div className={Style.PanelWrapper}>
                    {children}
                </div>
            </main>
            <LiveChat license={15833787} />
        </section>
    )
}

export default PanelLayout;