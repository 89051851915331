import {Tabs} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const SocialTabs = () => {

    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [activeKey, setActiveKey] = useState(null);

    useEffect(()=>{
        urlToKey(pathname)
    },[pathname]);

    const urlToKey = (path) => {
        if (pathname.includes(path)) {
            const find = items.find((x) => x.url === path);
            if(find){
                setActiveKey(find.key)
            }
        }
    }

    const onChange = (key) => {
        const find = items.find((x) => x.key === key);
        navigate(find.url)
        setActiveKey(key)
    }

    const items = [
        {
            key: 1,
            label: translate('social_login'),
            url: '/social/login',
        },
        {
            key: 2,
            label: translate('social_provider'),
            url: '/social/provider',
        },
        {
            key: 3,
            label: translate('top10'),
            url: '/social/top10',
        }
    ];

    return (
        <>
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
        </>
    )
}

export default SocialTabs;