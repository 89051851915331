import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import {Col, Row, Button, Skeleton} from "antd";
import {Link} from "react-router-dom";
import Meta from "../../assets/img/platform/meta.png";
import Android from "../../assets/img/platforms/android.svg";
import iOs from "../../assets/img/platforms/ios.svg";
import Windows from "../../assets/img/platforms/win.svg";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getDownloadsApi} from "../../api/api";
import Guides from "../../components/guides";
import {useSelector} from "react-redux";

const Platform = () => {

    const { t } = useTranslation();
    const translate = t;
    const [download, setDownload] = useState();
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");
    const token = useSelector((state) => state.auth?.token || null);

    useEffect(()=>{
        getDownloads();
        document.title = `${translate('wingo')} - ${translate('menu_platform')}`;
    },[]);

    const getDownloads = async () => {
        await getDownloadsApi(token).then((r)=>{
            setDownload(r.data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_platform')}</h1>
                <Row gutter={10}>
                    <Col md={8}>
                        <Guides type="loginMt5Android" locale={lang}/>
                    </Col>
                    <Col md={8}>
                        <Guides type="loginMt5ios" locale={lang}/>
                    </Col>
                    <Col md={8}>
                        <Guides type="loginMt5win" locale={lang}/>
                    </Col>
                </Row>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <Row gutter={24}>
                        <Col md={7}>
                            <div className={Style.Platform}>
                                <div className={Style.PlatformIcon}>
                                    <img src={Meta} alt=""/>
                                </div>
                                <div className={Style.PlatformText}>
                                    <h6>Meta Trader 5</h6>
                                    <p>MetaTrader 5 (MT5) trading terminal combines advanced technologies of order execution and distributed computations for implementing the most ambitious trading ideas. MetaTrader 5 is one of the most advanced platforms for creating and testing trading robots and strategies. If you value speed and reliability while working with trading robots, and want to use the most up-to-date modern technologies, then MetaTrader 5 platform is the best choice for you.</p>

                                    <div className={Style.DownloadBox}>
                                        <span>{translate('download')}</span>
                                        <ul className="p-0 m-0 d-flex list-unstyled justify-content-center">
                                            {download?.length ?download?.map((App, Index)=>{
                                                return (
                                                    <li key={Index}>
                                                        <Link to={App.link} target="_blank">
                                                            <img src={
                                                                App.title === "MT5 Windows" ? Windows :
                                                                    App.title === "MT5 IOS" ? iOs :
                                                                        App.title === "MT5 Android" ? Android :
                                                                            null}/>
                                                        </Link>
                                                    </li>
                                                )
                                            }) : <Skeleton.Button/>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </PanelLayout>
    )
}

export default Platform;