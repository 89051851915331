import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import FundsTabs from "../../../components/funds/tabs";
import {useEffect} from "react";
import History from "../../../components/funds/history";
import {useTranslation} from "react-i18next";

const PaymentHistory = () => {

    const { t } = useTranslation();
    const translate = t;

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('menu_funds_transfer_funds')}`;
    },[]);

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_funds')}</h1>
                <FundsTabs/>
                <h2>{translate('menu_history')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding} ${Style.PanelBoxHistory}`}>
                    <History type="pending" id={1}/>
                    <History style={{marginTop: "50px"}} type="approved" id={3}/>
                </div>
            </div>
        </PanelLayout>
    )
}

export default PaymentHistory;