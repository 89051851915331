import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/ib/tabs";
import {getCurrenciesApi, getIbPerformanceDashboardApi} from "../../../api/api";
import {useSelector} from "react-redux";
import moment from 'moment';
import {useEffect, useState} from "react";
import SettingIcon from "../../../assets/img/icon/settings-02.svg";
import {Col, Modal, Row, DatePicker, Select, Button} from "antd";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useTranslation} from "react-i18next";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);
const { RangePicker } = DatePicker;

const Performance = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [total, setTotal] = useState({});
    const [CommissionsChart, setCommissionsChart] = useState([]);
    const [VolumeChart, setVolumeChart] = useState([]);
    const [modal, setModal] = useState(false);
    const [date, setDate] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState(null);
    const nowDate = useState(moment().format("YYYY-M-D"));

    useEffect(()=>{
        getIbPerformanceDashboard(null)
        getCurrencies()
        document.title = `${translate('wingo')} - ${translate('menu_partnership_performance')}`;
    },[])

    const getCurrencies = async () => {
        await getCurrenciesApi(token).then((result)=> {
            const convertedArray = result.data.map(currencyCode => ({
                label: currencyCode,
                value: currencyCode,
            }));
            setCurrencies(convertedArray)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const getIbPerformanceDashboard = async (data) => {
        await getIbPerformanceDashboardApi(data, token).then((result)=>{

            const CommissionsChart = result.data?.charts.find((x)=>x.label == "Commissions (USD)");
            const VolumeChart = result.data.charts?.find((x)=>x.label == "Volume (Lots)");

            setCommissionsChart(CommissionsChart);
            setVolumeChart(VolumeChart);

            const CommissionsTotal = result.data?.totals.find((x)=>x.label == "Commissions");
            const VolumeTotal = result.data?.totals.find((x)=>x.label == "Total Volume");
            const RegisterTotal = result.data?.totals.find((x)=>x.label == "Registrations");

            setTotal({
                commissions: CommissionsTotal,
                volume: VolumeTotal,
                register: RegisterTotal
            })

        }).catch((error)=> {
            console.log(error)
        })
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    boxWidth: 20,
                    boxHeight: 20,
                    color: "#00A066",
                    font: {
                        size: 16
                    },
                    padding: 10
                }
            },
            title: {
                display: true,
                text: '',
            },
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    const labels = [];

    const CommissionsChartData = {
        labels,
        datasets: [{
            fill: true,
            backgroundColor: ({chart: {ctx}}) => {
                const bg = ctx.createLinearGradient(20, 0, 400, 500);
                bg.addColorStop(0, "rgba(0, 160, 102, 0.6)");
                bg.addColorStop(1, "rgba(201, 255, 158, 0.6)");
                return bg;
            },
            borderColor: 'rgba(0, 186, 175, 1)',
            label: CommissionsChart?.label,
            data: CommissionsChart?.data,
            lineTension: 0
        }]
    }

    const VolumeChartData = {
        labels,
        datasets: [{
            fill: true,
            backgroundColor: ({chart: {ctx}}) => {
                const bg = ctx.createLinearGradient(20, 0, 400, 500);
                bg.addColorStop(0, "rgba(0, 186, 175, 0.6)");
                bg.addColorStop(1, "rgba(195, 255, 251, 0.6)");
                return bg;
            },
            borderColor: 'rgba(0, 160, 102, 1)',
            data: VolumeChart?.data,
            label: VolumeChart?.label,
            lineTension: 0
        }]
    }

    const handleChangeDate = () => {
        let data;

        if(date.length){
            data = {
                from: date[0],
                to: date[1],
                currency: currency
            }

        } else {
            data = null;
        }

        getIbPerformanceDashboard(data)
        setModal(false)
    }


    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_partnership')}</h1>
                <ProfileTabs/>
                <h2>{translate('menu_partnership_performance')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>

                    <div className="d-flex align-items-center">
                        <div className={Style.PerformanceDate}>
                            <span>{translate('chart_settings')}:</span>
                            <bdi>{date.length ? date.toString() : nowDate}</bdi>
                            <strong>(USD)</strong>
                        </div>
                        <div className={`cursor-pointer ${Style.PerformanceSetting} d-flex align-items-center`} onClick={()=> setModal(true)}>
                            <div className={Style.AccountsOverviewBox} style={{padding: "10px"}}>
                                <img src={SettingIcon}/>
                            </div>
                        </div>
                    </div>

                    <div className={Style.PanelBoxTicket} style={{padding: "30px", marginTop: "30px"}}>
                        <Row>
                            <Col md={8} className={Style.PerformanceDashboardItem}>
                                <div className={Style.PerformanceItem}>
                                    <div>
                                        <bdi>{total.commissions?.data}</bdi>
                                        <span>{translate('ib_commissions')}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={8} className={Style.PerformanceDashboardItem}>
                                <div className={Style.PerformanceItem}>
                                    <div>
                                        <bdi>{total.volume?.data}</bdi>
                                        <span>{translate('ib_total_volume')}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={8} className={Style.PerformanceDashboardItem}>
                                <div className={Style.PerformanceItem}>
                                    <div>
                                        <bdi>{total.register?.data}</bdi>
                                        <span>{translate('ib_registrations')}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row gutter={70}>
                        <Col md={12} className="ant-col-full">
                            <div className={Style.ChartWrapper}>
                                <Line options={options} data={CommissionsChartData} />
                            </div>
                        </Col>
                        <Col md={12} className="ant-col-full">
                            <div className={Style.ChartWrapper}>
                                <Line options={options} data={VolumeChartData} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal
                centered
                closable={false}
                title={[
                    <div key={1} className={`${Style.AccountModalHeader} ${Style.AccountModalHeaderSmall}`}>
                        <span>{translate('filter_performance')}</span>
                    </div>
                ]}
                open={modal} onCancel={()=>setModal(false)}
                footer={[
                    <div key={1} className="d-flex justify-content-center" style={{marginBottom: "30px"}}>
                        <Button shape="round" onClick={()=> setModal(false)}>{translate('close')}</Button>
                        <Button disabled={!currency ? true : false} shape="round" className="dark-green-button white-color" onClick={handleChangeDate}>{translate('confirm')}</Button>
                    </div>
                ]}
            >
                <div className={Style.ChangeForm}>
                    <RangePicker
                        style={{borderRadius: "25px", marginBottom: "15px"}}
                        className="w-100"
                        format="YYYY-MM-DD"
                        onChange={(_, date)=>(_ ? setDate(date) : setDate([]))}
                    />
                    <div>
                        <Select placeholder={translate('funds_currency')} options={currencies} className="w-100" onChange={(choice)=> setCurrency(choice)}/>
                    </div>
                </div>
            </Modal>
        </PanelLayout>
    )
}

export default Performance;