import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enGB from './locales/en.json'
import faIR from './locales/fa.json';
import arAR from './locales/ar.json';
import frFR from './locales/fr.json';
import esES from './locales/es.json';

const resources = {
    en: {
        translation: enGB,
    },
    fa: {
        translation: faIR,
    },
    ar: {
        translation: arAR,
    },
    es: {
        translation: esES,
    },
    fr: {
        translation: frFR,
    }
};

const localstorage = localStorage.getItem("persist:root");
const state = localstorage && JSON.parse(localstorage);
let lang = "en";

if(state){
    const auth = state && state.auth && JSON.parse(state.auth);
    const setting = auth && auth.setting;
    lang = setting && setting.lang;
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;