import Style from "../../assets/css/panel.module.css";
import {Button, Skeleton} from "antd";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Wallets = ({wallet, ibWallet, loading}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const translate = t;
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");

    return (
        <>
            <h6>{translate('menu_funds_wallets')}</h6>
            <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>

                {loading ? <Skeleton active={loading}/> : null}

                {wallet?.login ?
                        <div>
                            <div className={`${Style.PanelBoxWrapper} d-md-flex justify-content-between align-items-center`}>
                                <div className={Style.PanelWallet}>
                                    <h4>{translate('wallet_balance')}</h4>
                                    <span>{wallet?.balance} <bdi>{wallet?.currency}</bdi></span>
                                </div>
                                <div className={Style.PanelWalletButtons}>
                                    <Button onClick={()=>navigate(`/funds/deposit/${wallet?.loginSid}`)} style={dir === "ltr" ? {marginRight: "10px"} : {marginLeft: "10px"}} className="dark-green-button white-color" shape="round">{translate('menu_funds_deposit')}</Button>
                                    <Button onClick={()=>navigate(`/funds/withdraw/${wallet?.loginSid}`)} style={dir === "ltr" ? {marginRight: "10px"} : {marginLeft: "10px"}} className="white-button dark-green-color" shape="round">{translate('menu_funds_withdraw')}</Button>
                                    <Button onClick={()=>navigate(`/funds/transfer/${wallet?.loginSid}`)} className="white-button" shape="round">{translate('funds_transfer')}</Button>
                                </div>
                            </div>
                        </div> : null}

                {ibWallet?.login ?
                    <div style={{marginTop: "20px"}}>
                        <div className={`${Style.PanelBoxWrapper} d-md-flex justify-content-between align-items-center`}>
                            <div className={Style.PanelWallet}>
                                <h4>{translate('ib_wallet_balance')}</h4>
                                <span>{ibWallet?.balance} <bdi>{ibWallet?.currency}</bdi></span>
                            </div>
                            <div className={Style.PanelWalletButtons}>
                                <Button onClick={()=>navigate(`/funds/transfer/${ibWallet?.loginSid}`)} className="white-button" shape="round">{translate('funds_transfer')}</Button>
                            </div>
                        </div>
                    </div> : null}

            </div>
        </>
    )
}

export default Wallets;