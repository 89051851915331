import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/ib/tabs";
import {
    createIbNewLinkApi,
    deleteIbLinkApi,
    editIbLinkApi,
    getIbLinksApi,
    getIbLinksLandingPagesApi
} from "../../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Button, Col, Input, message, Modal, Row, Select, Space, Table} from "antd";
import {Link} from "react-router-dom";
import Copy from "../../../assets/img/icon/copy.svg";
import {EditOutlined, DeleteOutlined, ExpandOutlined, SearchOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../../layout/components/windowSize";

const Links = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const [links, setLinks] = useState([]);
    const [linksDetails, setLinksDetails] = useState([]);
    const [linksData, setLinksData] = useState({});
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [editLink, setEditLink] = useState({});
    const [editId, setEditId] = useState(null);
    const [showInActive, setShowInActive] = useState(false);
    const {device} = useWindowSize();
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    useEffect(()=>{
        getIbLinks();
        getIbLinksLandingPages();
        document.title = `${translate('wingo')} - ${translate('menu_partnership_links')}`;
    },[]);

    useEffect(()=>{
        if(setShowInActive){
            getIbLinks()
        }
    },[showInActive])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getIbLinks = async () => {
        setLoading(true)
        await getIbLinksApi(token).then((result)=>{
            if(showInActive){
                result.data.filter((x)=>x.key = x.id)
                setLinks(result.data)
            } else {
                const activeLinks = result.data
                    .filter(link => link.isActive)
                    .map(link => ({ ...link, key: link.id }));
                setLinks(activeLinks)
            }

            setLoading(false)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getIbLinksLandingPages = async () => {
        await getIbLinksLandingPagesApi(token).then((result)=>{
            result.data.filter((x)=> (x.label = x.name, x.value = x.id))
            setLinksDetails(result.data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const copyToClipboard = (l) => {
        message.success("Copied!")
        navigator.clipboard.writeText(l)
    }

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        sorter: (a, b) => {
            const valueA = a[dataIndex];
            const valueB = b[dataIndex];

            if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
                return parseFloat(valueA) - parseFloat(valueB);
            } else {
                return valueA && valueA.localeCompare(valueB);
            }
        },
        sortDirections: ['ascend', 'descend'],
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                <span>{text}</span>
            ),
    });

    const columns = [
        {
            key: 'id',
            dataIndex: 'id',
            title: translate('id'),
            ...getColumnSearchProps('id', 'id')
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: translate('name'),
            ...getColumnSearchProps('name', 'name')
        },
        {
            key: 'link',
            dataIndex: 'link',
            title: translate('link'),
            render: (link) => <Link target="_blank" to={link}>{link}</Link>,
            ...getColumnSearchProps('link', 'link')
        },
        {
            key: 'copy',
            dataIndex: 'copy',
            title: '',
            render: (_, link) => (
                <Button
                    icon={<img width={14} height={14} src={Copy} />}
                    className="dark-green-button-border dark-green-color"
                    onClick={() => copyToClipboard(link.link)}
                    shape="round"
                >
                    {translate('click_to_copy')}
                </Button>
            )
        },
        {
            key: 'isActive',
            dataIndex: 'isActive',
            title: translate('active'),
            ...getColumnSearchProps('isActive', 'isActive'),
            render: (isActive) =>
                isActive ? <span className="dark-green-color">{translate('yes')}</span> : <span style={{ color: 'red' }}>{translate('no')}</span>
        },
        {
            key: 'clicks',
            dataIndex: 'clicks',
            title: translate('clicks'),
            ...getColumnSearchProps('clicks', 'clicks'),
        },
        {
            key: 'registrations',
            dataIndex: 'registrations',
            title: translate('ib_registrations'),
            ...getColumnSearchProps('registrations', 'registrations'),
        },
        {
            key: 'landingPage',
            dataIndex: 'landingPage',
            title: translate('ib_landing_page'),
            ...getColumnSearchProps('landingPage', 'landingPage'),
            render: (landingPage) => <span>{landingPage.name}</span>
        },
        {
            key: 'tools',
            dataIndex: 'tools',
            title: '',
            render: (_, link) =>
                link.isActive ? (
                    <>
                        <Button
                            onClick={() => handleEditLink(link.id)}
                            icon={<EditOutlined />}
                            className="dark-green-button-border dark-green-color"
                        >
                            {translate('edit')}
                        </Button>
                        <Button
                            onClick={() => deleteIbLink(link.id)}
                            style={dir === "ltr" ? { marginLeft: '10px' } : {marginRight: "10px"}}
                            shape="round"
                            icon={<DeleteOutlined />}
                            danger
                        >
                            {translate('delete')}
                        </Button>
                    </>
                ) : null
        }
    ];

    const handleEditLink = (id) => {
        const find = links.find((x)=>x.id = id);
        setEditId(find.id)
        setEditLink({
            name: find.name,
            optionId: find.landingPage.id
        });
        setModal(true)
    }

    const createIbNewLink = async () => {
        setButtonLoading(true)
        await createIbNewLinkApi(linksData, token).then(()=>{
            message.success(translate('error_created'))
            setButtonLoading(false)
            getIbLinks()
        }).catch((error)=>{
            console.log(error)
        })
    }

    const deleteIbLink  = async (id) => {
        await deleteIbLinkApi(id, token).then(()=>{
            message.success(translate('error_removed'))
            getIbLinks()
        }).catch((error)=> {
            console.log(error)
        })
    }

    const editIbLink  = async () => {
        await editIbLinkApi(editId, editLink, token).then(()=>{
            message.success(translate('error_edited'))
            getIbLinks()
            setModal(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_partnership')}</h1>
                <ProfileTabs />
                <h2>{translate('menu_partnership_links')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3>{translate('ib_links')}</h3>
                        <Button icon={<ExpandOutlined />} onClick={() => setShowInActive(!showInActive)} shape="round">
                            {showInActive ? translate('ib_hide_inactive_links') : translate('ib_show_inactive_links')}
                        </Button>
                    </div>

                    <Table
                        loading={loading}
                        className={Style.LinksTable}
                        dataSource={links}
                        columns={columns}
                        pagination={false}
                        scroll={{x: 560}}
                    />
                </div>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`} style={{ marginTop: "30px" }}>
                    <h3>{translate('ib_create_new_link')}</h3>

                    <Row gutter={20} className={Style.LinksForm}>
                        <Col md={12}>
                            <div className="d-md-flex">
                                <label className="text-nowrap">{translate('name')}</label>
                                <div>
                                    <Input className="deposit-select" style={{ borderRadius: "25px" }} onChange={(e) => setLinksData({ ...linksData, name: e.target.value })} />
                                    <small>{translate('ib_message1')}</small>
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="d-md-flex" style={device === "mobile" ? {marginTop: "15px"} : null}>
                                <label className="text-nowrap">{translate('ib_landing_page')}</label>
                                <div style={device === "mobile" ? {marginBottom: "15px"} : null}>
                                    <Select
                                        options={linksDetails}
                                        className="w-100 deposit-select"
                                        onChange={(ch) => setLinksData({ ...linksData, optionId: ch })}
                                    />
                                    <small>{translate('ib_message2')}</small>
                                </div>

                                <Button onClick={() => createIbNewLink()} loading={buttonLoading} shape="round" className="dark-green-button white-color" style={dir === "ltr" && device !== "mobile" ? { marginLeft: "20px" } : dir === "rtl" && device !== "mobile" ? { marginRight: "20px" } : null}>{translate('continue')}</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal
                centered={true}
                closeIcon={false}
                className="links-modal"
                open={modal}
                title={[
                    <div key={1} className={`${Style.AccountModalHeader} ${Style.AccountModalHeaderSmall}`}>
                        <span>{translate('menu_partnership_links')}</span>
                    </div>
                ]}
                footer={[
                    <Button onClick={() => setModal(false)} shape="round" key={1}>{translate('close')}</Button>,
                    <Button onClick={() => editIbLink(editLink.id)} className="dark-green-button white-color" shape="round" key={2}>{translate('edit')}</Button>
                ]}
            >
                <div>
                    <Input
                        style={{ borderRadius: "25px" }}
                        value={editLink?.name}
                        onChange={(e) => setEditLink({ ...editLink, name: e.target.value })}
                    />
                </div>
                <div style={{ marginTop: "20px" }}>
                    <Select
                        className="w-100"
                        options={linksDetails}
                        value={editLink?.optionId}
                        onChange={(ch) => setEditLink({ ...editLink, optionId: ch })}
                    />
                </div>
            </Modal>
        </PanelLayout>
    )
}

export default Links;