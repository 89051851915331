import {
    depositApi,
    getAccountApi,
    getPaymentSystemsForDepositByLoginSidApi
} from "../../../api/api";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Col, Input, message, notification, Row, Select, Skeleton} from "antd";
import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import FundsTabs from "../../../components/funds/tabs";
import Verified from "../../../assets/img/icon/check-verified.svg";
import {useTranslation} from "react-i18next";
import {currencies} from "../../../api/data";
import {Api} from "../../../api/config";
import Crypto from "../../../assets/img/icon/method/crypto.svg";
import Wallet from "../../../assets/img/icon/method/wallet.svg";
import CreditCard from "../../../assets/img/icon/method/credit-card.svg";
import {useWindowSize} from "../../../layout/components/windowSize";
import { CopyOutlined } from '@ant-design/icons';
import Guides from "../../../components/guides";

const Index = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const [step, setStep] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [deposit, setDeposit] = useState({});
    const [currency, setCurrency] = useState([]);
    const [readyForGate, setReadyForGate] = useState(false);
    const formRef = useRef(null);
    const [depositResult, setDepositResult] = useState({});

    const [CryptoMethods, setCryptoMethods] = useState([]);
    const [eWalletsMethods, setEWalletsMethods] = useState([]);
    const [DebitCardMethods, setDebitCardMethods] = useState([]);

    const [DisplayCryptoMethods, setDisplayCryptoMethods] = useState(true);
    const [DisplayEWalletsMethods, setDisplayEWalletsMethods] = useState(true);
    const [DisplayDebitCardMethods, setDisplayDebitCardMethods] = useState(true);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    const {device} = useWindowSize();

    useEffect(()=>{
        if(token){
            getAccounts();
        }
        document.title = `${translate('wingo')} - ${translate('menu_funds_deposit')}`;
    },[]);

    useEffect(()=>{
        if (formRef.current) {
            formRef.current.submit();
        }
    },[readyForGate])

    const handleCurrencies = (id) => {
        if(id === 3){
            setCurrency(currencies)
        }
    }

    const getPaymentSystemsForDeposit = async (id) => {
        await getPaymentSystemsForDepositByLoginSidApi(id, token).then((result)=>{
            const methods = result.data;

            const array1 = methods?.filter(m => m.paymentDetailsConfigId === 24) || [];
            const array2 = methods?.filter(m => m.paymentDetailsConfigId === 23) || [];
            const array3 = methods?.filter(m => m.paymentDetailsConfigId === 26) || [];

            setDisplayCryptoMethods(array1.length ? true : false);
            setDisplayEWalletsMethods(array2.length ? true : false);
            setDisplayDebitCardMethods(array3.length ? true : false);

            setCryptoMethods(array1);
            setEWalletsMethods(array2);
            setDebitCardMethods(array3);

        }).catch((error)=>{
            console.log(error)
        })
    }

    const getAccounts = async () => {
        const data = {
            scope: "deposit"
        }

        await getAccountApi(data, token).then((result)=>{
            const liveAccountsArray = [];

            result.data.map(item => {
                if (item.typeId === 1) {
                    liveAccountsArray.push(item);
                }
            });

            const walletAccount = liveAccountsArray?.filter(x => x.typeId === 1) || [];
            const w = walletAccount[0];
            handleWalletMethod(w?.login, w);

        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleWalletMethod = (id, value) => {
        setDeposit({...deposit, wallet: id, loginSid: value.loginSid, walletType: value.type.title})
        getPaymentSystemsForDeposit(value.loginSid)
    }

    const handleMethod = (Conf) => {
        if(Conf.id === 5){
            notification.info({
                message: "Payment Method Unavailable",
                description: "We apologize for the inconvenience, but this payment method is currently unavailable as we are making updates. Please feel free to use one of our other available payment options."
            })
        } else {
            setStep(1)
            handleCurrencies(Conf.id)
            setDeposit({...deposit, method: Conf})
        }

    }

    const handleCopy = (v) => {
        message.success(translate('copied'))
        navigator.clipboard.writeText(v)
    }

    const depositRequest = async (paymentMethod) => {
        setButtonLoading(true)
        const data = {
            paymentSystem: deposit?.method?.id,
            account: deposit?.loginSid
        }

        await depositApi(data, token).then((result)=>{
            const data = convertedData(result.data.form.fields);
            let newData;

            if(deposit?.method.id === 4){
                newData = {"form": {...data, paymentMethodOption: deposit?.method?.displayName}}
            } else {
                newData = {"form": data}
            }

            depositApi(newData, token).then((result)=>{
                const data = convertedData(result.data.form.fields)
                const newData = {"form": data}

                depositApi(newData, token).then((result)=> {
                    if(result.data.depositResult){
                        setStep(3);
                        setDepositResult(result.data.depositResult);
                        if(result.data.depositResult.redirectUrl){
                            window.location.href = result.data.depositResult.redirectUrl
                        } else {
                            setReadyForGate(true);
                        }

                        setButtonLoading(false)
                    } else {
                        setButtonLoading(false)
                    }
                });
            })
        }).catch((error)=>{
            console.log(error)
            notification.error({
                description: "Error"
            })
        })
    }

    const convertedData = (fields) => fields.reduce((acc, item) => {
        if (item.name === 'amount') {
            acc.amount = deposit?.amount
        } else if (item.name === 'calculatedAmount') {
            acc.calculatedAmount = deposit?.amount
        } else if (item.name === 'paymentMethodOption') {
            acc.paymentMethodOption = deposit?.currency
        } else {
            acc[item.name] = item.value;
        }
        return acc;
    }, {});

    const MethodTemplate = ({methods, title, icon}) => {
        return (
            <>
                <div className={Style.PanelBox} style={{marginBottom: "30px"}}>
                    <div className={Style.PanelWrapperPadding} style={device !== "mobile" ? {paddingTop: "45px"} : null}>
                        <div className={Style.DepositMethodIcon}>
                            <img style={dir === "ltr" ? {marginRight: "10px"} : {marginLeft: "10px"}} src={icon}/>
                            <h3>{title}</h3>
                        </div>

                        <div style={device !== "mobile" ? {marginTop: "30px"} : null}>
                            <ul className={`${Style.DepositHead} d-flex justify-content-between list-unstyled m-0 p-0`}>
                                <li><span>{translate('transfer_method')}</span></li>
                                <li><span>{translate('processing_time')}</span></li>
                                <li><span>{translate('min_max_dep')}</span></li>
                                <li><span>{translate('fee_commission')}</span></li>
                                <li><span></span></li>
                            </ul>
                            {methods?.length ?
                                <ul className={`${Style.DepositMethodItem} ${Style.DepositDetailsItems}`}>
                                    {methods.map((Method, Index)=>{
                                        return (
                                            <li key={Index}>
                                                <div className={`${Style.DepositItem} ${Style.DepositItemList}`}>
                                                    <img width={50} src={Api + Method.logo} />
                                                    <h5>{Method.displayName}</h5>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <bdi>{translate('processing_time')}</bdi>
                                                    <span>{translate('instant')}</span>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <bdi>{translate('min_max_dep')}</bdi>
                                                    <span>{translate('min')}: <strong style={{marginLeft: "5px", display: "inline-block"}}>$10</strong></span>
                                                    <span>{translate('max')}: {translate('unlimited')}</span>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <bdi>{translate('fee_commission')}</bdi>
                                                    <span>-</span>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <Button className="dark-green-button-border dark-green-color" shape="round" onClick={() => handleMethod(Method)}>{translate('menu_funds_deposit')}</Button>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul> :
                            <Skeleton/>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_funds')}</h1>
                {deposit?.method?.id === 2 ?
                    <Guides type="depositAdvcash" locale={lang}/> :
                    deposit?.method?.id === 5 ?
                        <Guides type="depositDebit" locale={lang}/> :
                        deposit?.method?.id === 3 ?
                            <Guides type="depositCrypto" locale={lang}/> :
                    null}

                <FundsTabs/>
                <h2>{translate('menu_funds_deposit')}</h2>

                {step === 0 ?
                    <>

                        {DisplayCryptoMethods ? <MethodTemplate methods={CryptoMethods} title="Crypto" icon={Crypto}/> : null}
                        {DisplayEWalletsMethods ? <MethodTemplate methods={eWalletsMethods} title="e-wallets" icon={Wallet}/> : null}
                        {DisplayDebitCardMethods ? <MethodTemplate methods={DebitCardMethods} title="Debit Card" icon={CreditCard}/> : null}
                    </>
                    :
                    <div className={Style.PanelBox}>
                        <div className={Style.PanelWrapperPadding}>
                            {step === 1 ?
                                <>
                                    <div style={{marginBottom: "50px"}}>
                                        <div className={`${Style.DepositItem} ${Style.DepositItemList}`}>
                                            <img width={50} src={Api + deposit?.method?.logo} />
                                            <h5>{deposit?.method?.displayName}</h5>
                                        </div>
                                    </div>
                                    <Row gutter={50} className={Style.DepositMethodForm}>
                                        <Col md={12} className="ant-col-full">
                                            <div className="d-md-flex" style={{ marginBottom: "27px" }}>
                                                <label className="text-nowrap" style={{ paddingTop: "8px" }}>{translate('funds_deposit_amount')}</label>
                                                <div className="w-100">
                                                    <Input type="number" className="w-100 deposit-select" value={deposit?.amount} suffix="$" onChange={(e) => setDeposit({ ...deposit, amount: e.target.value })} />
                                                </div>
                                            </div>
                                            {deposit?.method?.id === 3 ?
                                                <div className="d-md-flex" style={{ marginBottom: "27px" }}>
                                                    <label className="text-nowrap" style={{ paddingTop: "8px" }}>{translate('funds_payment_method')}</label>
                                                    <Select
                                                        value={deposit?.currency}
                                                        placeholder={translate('select_option')}
                                                        className="w-100 deposit-select"
                                                        options={currency}
                                                        onChange={(choice) => setDeposit({ ...deposit, currency: choice })}
                                                    />
                                                </div> : null}

                                        </Col>
                                        <Col md={12} className="ant-col-full">
                                            <div className="d-md-flex">
                                                <label className="text-nowrap" style={{ paddingTop: "8px" }}>{translate('funds_credited_amount')}</label>
                                                <div className="w-100">
                                                    <Input className="deposit-select" value={deposit?.amount} readOnly suffix="$" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className={Style.DepositDetails}>
                                        <h3>{translate('deposit_details')}</h3>
                                        <ul className={`${Style.DepositHead} d-flex justify-content-between list-unstyled m-0 p-0`}>
                                            <li><span>{translate('funds_deposit_to')}</span></li>
                                            <li><span>{translate('deposit_method')}</span></li>
                                            <li><span>{translate('processing_time')}</span></li>
                                            <li><span>{translate('fee_commission')}</span></li>
                                            <li><span>{translate('fees')}</span></li>
                                        </ul>
                                        <ul className={`${Style.DepositDetailsItems} p-0 d-md-flex`} style={{margin: "20px 0 0 0"}}>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('funds_deposit_to')}</bdi>
                                                <span>{deposit?.walletType} {deposit?.loginSid}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('deposit_method')}</bdi>
                                                <span>{deposit?.method?.displayName}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('processing_time')}</bdi>
                                                <span>{translate('instant')}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('fee_commission')}</bdi>
                                                <span>{translate('no_commission')}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('fees')}</bdi>
                                                <span>{deposit?.method.id === 5 ? `7` : `0`}%</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={`${Style.DepositMethod} ${Style.DepositNotes}`}>
                                        <h5>
                                            <span>{translate('funds_deposit_min_amount')}</span>
                                            <bdi style={dir === "ltr" ? { marginLeft: "10px" } : { marginRight: "10px" }}>10.00 USD</bdi>
                                        </h5>

                                        <p>{translate('funds_deposit_message2')}</p>
                                    </div>
                                </>
                                : step === 2 ?
                                    <>
                                        <ul className={`${Style.DepositReview} list-unstyled m-0 p-0`}>
                                            <li>
                                                <span>{translate('funds_deposit_with')}</span>
                                                <bdi>{deposit?.method?.displayName}</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('funds_deposit_to')}</span>
                                                <bdi>Wallet {deposit?.wallet}</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('funds_deposit_amount')}</span>
                                                <bdi>{deposit?.amount} USD</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('funds_amount_credited')}</span>
                                                <bdi>{deposit?.amount} USD</bdi>
                                            </li>
                                        </ul>
                                    </>
                                    : step === 3 ?
                                        <>
                                            <div className={Style.DepositSuccess}>
                                                {depositResult?.url ?
                                                    <>
                                                        <p>{translate('redirecting')}</p>
                                                        <form action={depositResult?.url} method="POST" ref={formRef}>
                                                            {Object.entries(depositResult?.params).map(([Key, Value], Index)=>{
                                                                return (
                                                                    <Input type="hidden" name={Key} value={Value} key={Index}/>
                                                                )
                                                            })}
                                                            <Button shape="round">{translate('click_redirect')}</Button>
                                                        </form>
                                                    </>
                                                    : depositResult?.redirectUrl ?
                                                        <>
                                                            <p>{translate('redirecting')}</p>
                                                            <Button shape="round" onClick={()=>window.location.href = depositResult?.redirectUrl}>{translate('click_redirect')}</Button>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="text-center" style={{marginBottom: "15px"}}>
                                                                <img width={87} height={87} src={Verified} />
                                                                <p>{translate('deposit_message')}</p>
                                                                <strong style={{display: "block", marginBottom: '20px'}}>{depositResult?.params?.cryptoAddress}</strong>
                                                                <Button className="dark-green-button-border dark-green-color" onClick={()=> handleCopy(depositResult?.params?.cryptoAddress)} style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}} shape="round" icon={<CopyOutlined />}>{translate('click_to_copy')}</Button>
                                                                <br/>
                                                                <br/>
                                                                <img src={`data:image/png;base64,${depositResult?.params?.qr}`}/>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-center" style={{marginTop: "30px"}}>
                                                                <Button onClick={()=> (setStep(0), setDeposit({...deposit, amount: null, currency: null}))} style={{borderRadius: "25px"}} size="large" className="dark-green-button white-color">{translate('new_deposit')}</Button>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </>
                                        : null
                            }
                            <div className={`d-flex justify-content-end ${Style.DepositMethodButtons}`}>
                                {step > 0 && step < 3 ? <Button shape="round" className="black-color" onClick={()=>setStep(step > 0 ? step - 1 : 0)}>{translate('back')}</Button> : null}
                                {step < 3 ?
                                    <Button
                                        loading={buttonLoading}
                                        shape="round"
                                        className="dark-green-button white-color"
                                        disabled={
                                            step === 0 ? !deposit?.wallet || !deposit?.method?.id
                                                : step === 1 ? !deposit?.amount || (deposit?.method?.id === 3 ? !deposit?.currency : null) : false
                                        }
                                        onClick={()=> (step === 2 ? depositRequest() : setStep(step < 3 ? step + 1 : 4))}
                                    >
                                        {step === 2 ? translate('confirm') : translate('continue')}
                                    </Button> : null}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </PanelLayout>
    )
}

export default Index;