import Style from "../../assets/css/panel.module.css";
import WalletIcon from "../../assets/img/icon/wallet.svg";
import {Alert, Button, Skeleton} from "antd";
import {useNavigate} from "react-router-dom";
import IBIcon from "../../assets/img/icon/IB.svg";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const WalletList = ({wallet, loading, type}) => {

    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");

    return (
        <>
            {wallet ?
                <li className={Style.PanelBoxWrapper}>
                    <div className={Style.WalletDetails}>
                        <div className="d-flex">
                            <div className={Style.PanelBoxIcon}><img src={type === 1 ? WalletIcon : type === 2 ? IBIcon : null} /></div>
                            <div>
                                <h6>{type === 1 ? translate('wallet_balance') : type === 2 ? translate('ib_wallet_balance') : null}</h6>
                                <div className={Style.WalletListAmount}>
                                    {loading ?
                                        <Skeleton.Input active={loading} /> :
                                        <>
                                            <span>{wallet?.balance}</span>
                                            <bdi>{wallet?.currency}</bdi>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            {type === 1 ?
                                <>
                                    <Button disabled={!Object.entries(wallet)?.length} onClick={() => navigate(`/funds/deposit/${wallet.loginSid}`)} style={dir === "ltr" ? { marginRight: "10px" } : { marginLeft: "10px" }} className="dark-green-button white-color" shape="round">{translate('menu_funds_deposit')}</Button>
                                    <Button disabled={!Object.entries(wallet)?.length} onClick={() => navigate(`/funds/withdraw/${wallet.loginSid}`)} style={dir === "ltr" ? { marginRight: "10px" } : { marginLeft: "10px" }} className="white-button dark-green-color" shape="round">{translate('menu_funds_withdraw')}</Button>
                                    <Button disabled={!Object.entries(wallet)?.length} onClick={() => navigate(`/funds/transfer/${wallet.loginSid}`)} className="white-button" shape="round">{translate('funds_transfer')}</Button>
                                </>
                                : type === 2 ?
                                    <Button disabled={!Object.entries(wallet)?.length} onClick={() => navigate(`/funds/transfer/${wallet.loginSid}`)} className="white-button" shape="round">{translate('funds_transfer')}</Button> :
                                    null
                            }
                        </div>
                    </div>
                </li>
                : type === 1 ? <Alert message={translate('no_wallet')} type="warning" showIcon /> : null}
        </>
    )
}

export default WalletList;