import PanelLayout from "../../layout/panel";
import {Alert, Button, Col, Row, Space} from "antd";
import Style from '../../assets/css/panel.module.css';
import Quick from "../../components/dashboard/quick";
import Wallets from "../../components/dashboard/wallets";
import News from "../../components/dashboard/news";
import Overview from "../../components/dashboard/overview";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAccountApi} from "../../api/api";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Index = () => {

    const navigate = useNavigate();
    const token = useSelector((state) => state.auth?.token || null);
    const user = useSelector((state) => state.auth?.user || {});
    const { t } = useTranslation();
    const translate = t;
    const [demoAccounts, setDemoAccounts] = useState([]);
    const [liveAccounts, setLiveAccounts] = useState([]);
    const [wallet, setWallet] = useState( null);
    const [ibWallet, setIbWallet] = useState( null);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        getAccounts()
        document.title = `${translate('wingo')} - ${translate('menu_my_home')}`;
    },[]);

    const getAccounts = async () => {
        setLoading(true)
        const data = {
            category: null,
            scope: null
        }
        await getAccountApi(data, token).then((result)=>{
            const demoAccountsArray = [];
            const liveAccountsArray = [];

            result.data.map(item => {
                if (item.typeId === 7 || item.typeId === 14 || item.typeId === 15 || item.typeId === 16) {
                    demoAccountsArray.push(item);
                } else if(item.typeId !== 1 && item.typeId !== 2) {
                    liveAccountsArray.push(item);
                } else if(item.typeId === 1){
                    setWallet(item)
                } else if(item.typeId === 2){
                    setIbWallet(item)
                }
            });

            demoAccountsArray.filter((x)=> (x.key = x.login))
            liveAccountsArray.filter((x)=> (x.key = x.login))

            setDemoAccounts(demoAccountsArray)
            setLiveAccounts(liveAccountsArray)

            setLoading(false)
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            {!user?.isVerified ?
                <div style={{marginTop: "25px"}}>
                    <Alert
                        message={<strong>{translate('dashboard_dear_client')}</strong>}
                        showIcon
                        description={translate('dashboard_verification_alert')}
                        type="warning"
                        action={
                            <Button size="large" onClick={()=>navigate('/profile/verification')}>
                                {translate('menu_verification')}
                            </Button>
                        }
                    />
                </div>
                : null}
            <Quick/>
            <Row className={Style.DashboardSection} gutter={25}>
                <Col xxl={12} xl={14} lg={24} xs={24}>
                    <Wallets wallet={wallet} ibWallet={ibWallet} loading={loading}/>
                    <News/>
                </Col>
                <Col xxl={12} xl={10} lg={24} xs={24}>
                    <Overview
                        demoAccounts={demoAccounts}
                        liveAccounts={liveAccounts}
                        loading={loading}
                    />
                </Col>
            </Row>
        </PanelLayout>
    )
}

export default Index;