import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import {useTranslation} from "react-i18next";
import VPSTabs from "../../components/vps/tabs";

const Vps = () => {

    const { t } = useTranslation();
    const translate = t;

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_request_vps')}</h1>
                <VPSTabs/>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('vps_my')}</h3>
                </div>
            </div>
        </PanelLayout>
    )
}

export default Vps;