import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const Terminal = () => {

    const { t } = useTranslation();
    const translate = t;

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('menu_web_terminal')}`;
    },[]);

    return (
    <PanelLayout>
        <div className={Style.Main}>
            <h1>{translate('menu_web_terminal')}</h1>

            <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                <iframe
                    src="https://webtrading5.wingomarkets.com/terminal?mode=connect&marketwatch=EURUSD,GBPUSD,USDJPY&utm_campaign=webterminal&utm_source=site"
                    height="600px"
                    width="100%"
                    style={{border: "unset"}}
                ></iframe>

            </div>
        </div>
    </PanelLayout>
    )
}

export default Terminal;