import {Alert, Button, Space} from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import {Domain} from "../../api/config";
import {useTranslation} from "react-i18next";

const Guides = ({type, locale}) => {

    const { t } = useTranslation();
    const translate = t;

    const getGuideAttr = () => {
        let title, link;
        let lang = locale === "en" ? "English" : locale === "fa" ? "Farsi" : "English";

        switch(type) {
            case "changePassword":
                title = "Change Account Password";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-change-account-password-${locale}-wingomarkets.pdf`
                break;
            case "changeDemoPassword":
                title = "Change Demo Account Password";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-change-demo-account-password-${locale}-wingomarkets.pdf`
                break;
            case "changeLivePassword":
                title = "Change Live Account Password";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-change-live-account-password-${locale}-wingomarkets.pdf`
                break;
            case "createLiveAccount":
                title = "Create Live Account";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-create-a-live-account-${locale}-wingomarkets.pdf`
                break;
            case "createDemoAccount":
                title = "Create Demo Account";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-create-a-demo-account-${locale}-wingomarkets.pdf`
                break;
            case "createTicket":
                title = "Create Ticket";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-create-a-ticket-${locale}-wingomarkets.pdf`
                break;
            case "depositAdvcash":
                title = "Deposit With AdvCash";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-deposit-with-advcash-${locale}-wingomarkets.pdf`
                break;
            case "depositDebit":
                title = "Deposit With Debit Card";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-deposit-with-visa-master-${locale}-wingomarkets.pdf`
                break;
            case "depositCrypto":
                title = "Deposit With Crypto";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-deposit-crypto-${locale}-wingomarkets.pdf`
                break;
            case "loginMt5Android":
                title = "Login MT5 With Android";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-login-to-mt5-android-${locale}-wingomarkets.pdf`
                break;
            case "loginMt5ios":
                title = "Login MT5 With iOS";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-login-to-mt5-ios-${locale}-wingomarkets.pdf`
                break;
            case "loginMt5win":
                title = "Login MT5 With Windows";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-login-to-mt5-windows-${locale}-wingomarkets.pdf`
                break;
            case "register":
                title = "Register";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-register-${locale}-wingomarkets.pdf`
                break;
            case "requestIB":
                title = "Request IB";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-request-ib-${locale}-wingomarkets.pdf`
                break;
            case "transferFunds":
                title = "Transfer Funds";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-transfer-funds-${locale}-wingomarkets.pdf`
                break;
            case "withdraw":
                title = "Withdraw";
                link = `${Domain}/uploads/public/guides/${lang}/How-to-withdraw-${locale}-wingomarkets.pdf`
                break;
            case "ibPanel":
                title = "IB Panel";
                link = `${Domain}/uploads/public/guides/${lang}/IB-panel-${locale}-wingomarkets.pdf`
                break;
            case "KYC":
                title = "KYC";
                link = `${Domain}/uploads/public/guides/${lang}/KYC-${locale}-wingomarkets.pdf`
                break;
            case "socialTrading":
                title = "Social Trading";
                link = `${Domain}/uploads/public/guides/${lang}/Social-Trading-Complete-Guide-${locale}-wingomarkets.pdf`
                break;
            default:
                title = "Our";
                link = "#"
        }

        return {title, link}
    }

    return (
        <Alert
            style={{margin: "20px 0", background: "#DFFFF3", border: "1px solid #c1e7d9"}}
            description={
            <>
                <span style={{color: "#00a066", marginRight: "10px"}}>{translate('guide', {param: getGuideAttr(type)?.title})}</span>
                <Button
                    target="_blank"
                    type="link"
                    icon={<DownloadOutlined style={{fontSize: "20px", color: "#00a066"}}/>}
                    href={getGuideAttr(type)?.link}
                />
            </>
        }
            type="info"
            closable
        />
    )
}

export default Guides;