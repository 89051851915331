import {
    SET_TOKEN,
    SET_USER_DATA,
    SET_SETTING,
    SET_CONTACT_MANAGER,
    SET_NAV,
    SET_THEME,
    SET_UNREAD,
    SET_REFERRAL
} from "../constants/user";

export const setUserData = x => ({
    type: SET_USER_DATA,
    payload: x
});

export const setToken = x => ({
    type: SET_TOKEN,
    payload: x
});

export const setSetting = x => ({
    type: SET_SETTING,
    payload: x
});

export const setContactManager = x => ({
    type: SET_CONTACT_MANAGER,
    payload: x
});

export const setNav = x => ({
    type: SET_NAV,
    payload: x
});

export const setTheme = x => ({
    type: SET_THEME,
    payload: x
});

export const setUnread = x => ({
    type: SET_UNREAD,
    payload: x
});

export const setReferral = x => ({
    type: SET_REFERRAL,
    payload: x
});