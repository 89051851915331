import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/profile/tabs";
import PanelLayout from "../../../layout/panel";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useSelector} from "react-redux";
import SumsubWebSdk from '@sumsub/websdk-react'
import {Node_Api} from "../../../api/config";
import {Spin} from "antd";
import Guides from "../../../components/guides";


const Verification = () => {

    const { t } = useTranslation();
    const translate = t;
    const user = useSelector((state) => state.auth?.user || null);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");
    const [loading, setLoading] = useState(false);
    const [accessToken, setAccessToken] = useState("");

    useEffect(()=>{
        getNewAccessToken();
        document.title = `${translate('wingo')} - ${translate('Verification')}`;
    },[]);


    const getNewAccessToken = async () => {
        setLoading(true)
        await axios.post(`${Node_Api}/generateAccessToken`,
            {user: user.id}
        ).then((r)=>{
            setLoading(false)
            setAccessToken(r.data.accessToken)
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_profile')}</h1>
                <Guides type="KYC" locale="lang"/>
                <ProfileTabs/>
                <h2>{translate('menu_verification')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('menu_verification')}</h3>
                    <div className="d-flex align-items-center justify-content-center">
                        {loading ? <Spin/> : null}
                    </div>
                    {accessToken ? <SumsubWebSdk
                        accessToken={accessToken}
                        config={{ lang: lang, email: user?.email, phone: user?.phone, }}
                        options={{ addViewportTag: false, adaptIframeHeight: true}}
                        expirationHandler={getNewAccessToken}
                        updateAccessToken={()=>getNewAccessToken()}
                    /> : null}
                </div>
            </div>
        </PanelLayout>
    )
}

export default Verification;