import {
    changeEmailApi, changeLanguageApi, changePhoneApi,
    changeProfilePasswordApi, getAvailableLanguagesApi,
    getNotificationPreferencesOptionsApi,
    getProfileApi,
    sendPinApi, updateNotificationPreferencesApi, updateProfileApi
} from "../../api/api";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import ProfileTabs from "../../components/profile/tabs";
import {Col, Checkbox, Row, Button, Modal, Input, notification, message, Select, Switch, Spin} from "antd";
import {EditOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {useNavigate} from "react-router-dom";
import Verified from "../../assets/img/icon/check-verified.svg"
import NotVerified from "../../assets/img/icon/not-verified.svg"
import {setSetting} from "../../redux/actions/user";
import {useTranslation} from "react-i18next";
import {countriesCode} from "../../api/data";

const Profile = () => {

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const [languages, setLanguages] = useState([]);
    const [profile, setProfile] = useState();
    const [preferences, setPreferences] = useState({});
    const [preferencesData, setPreferencesData] = useState([]);
    const [modal, setModal] = useState({});
    const [passwordData, setPasswordData] = useState({});
    const [changeMailStep, setChangeMailStep] = useState(0);
    const [changeMailData, setChangeMailData] = useState({});
    const [changePhoneStep, setChangePhoneStep] = useState(0);
    const [changePhoneData, setChangePhoneData] = useState({code: '98'});
    const [changeLanguageData, setChangeLanguageData] = useState({});
    const [switchToggle, setSwitchToggle] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState({current: false, new: false, confirm: false});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [phoneCodes, setPhoneCodes] = useState([]);

    useEffect(()=>{
        getProfile()
        getNotificationPreferencesOptions()
        getAvailableLanguages()

        const codes = countriesCode;
        codes.map((x, i)=>x.key = i + 1)
        codes.filter((x)=>(x.label = `${x.iso} (+${x.value})`))
        setPhoneCodes(codes)

    },[])

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('menu_profile')}`;
    },[]);

    const getProfile = async () => {
        await getProfileApi(token).then((result)=>{
            setProfile(result.data)
            setPreferences(result.data.notificationPreferences)
            setSwitchToggle(result.data.smsNotificationEnabled)
            setLoading(false)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const getNotificationPreferencesOptions = async () => {
        await getNotificationPreferencesOptionsApi(token).then((result)=>{
            setPreferencesData(result.data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleModal = (type) => {
        setModal({...modal, type: type, status: true})
    }

    const handlePassword = () => {
        if((passwordData?.newPassword === passwordData?.confirmPassword) && passwordData?.oldPassword){
            if(passwordData?.newPassword.length > 8){
                changePassword()
            } else {
                notification.error({
                    description: "Password should be more than 8 characters!"
                })
            }
        } else {
            notification.error({
                description: "Password not match!"
            })
        }
    }

    const changePassword = async () => {
        let {confirmPassword, ...data} = passwordData;
        setButtonLoading(true)

        await changeProfilePasswordApi(data, token).then(()=>{
            setModal({...modal, status: false})
            setPasswordData({});

            message.success("Password changed successfully!")
            setButtonLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const handleCheckboxChange = (key, value) => {
        const originalObject = {...preferences, [key]: value};
        const newArray = Object.keys(originalObject).map(key => ({
            key: key,
            value: originalObject[key]
        }));

        updateNotificationPreferences(newArray)
        setPreferences({...preferences, [key]: value});

    }

    const handleModalAction = (type) => {
        if(type === "password"){
            handlePassword()
        } else if(type === "mail"){
            if(changeMailStep === 0){
                sendPin('changeEmail')
            } else if(changeMailStep === 1){
                changeEmail()
            }
        } else if(type === "phone"){
            if(changePhoneStep === 0){
                sendPin('changePhone')
            } else if(changePhoneStep === 1){
                changePhone()
            }
        } else if(type === "lang"){
            changeLanguage()
        }
    }

    const sendPin = async (type) => {
        const data = {
            action: type,
            method: "email"
        }
        setButtonLoading(true)
        await sendPinApi(data, token).then(()=>{
            if(modal?.type === "mail"){
                setChangeMailStep(1)
            } else if(modal?.type === "phone"){
                setChangePhoneStep(1)
            }
            message.success("Pin sent successfully")
            setButtonLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const changeEmail = async () => {
        setButtonLoading(true)
        await changeEmailApi(changeMailData, token).then(()=>{
            setModal({...modal, status: false})
            message.success("Mail changed successfully!")
            getProfile()
            setButtonLoading(false)
        }).catch((error)=>{
            console.log(error)
            message.error("Invalid Data!")
        })
    }

    const getAvailableLanguages = async () => {
        await getAvailableLanguagesApi(token).then((result)=>{
            result.data.filter((x)=> (x.label = x.name, x.value = x.language))
            setLanguages(result.data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const changeLanguage = async () => {
        setButtonLoading(true)
        await changeLanguageApi(changeLanguageData, token).then((result)=>{
            const lang = result.data.language;
            setModal({...modal, status: false})
            message.success("Language changed successfully!")
            dispatch(setSetting({lang: lang, dir: lang === "en" ? "ltr" : "rtl" }))
            getProfile()
            setButtonLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const changePhone = async () => {
        setButtonLoading(true)
        const mergePhone = { ...changePhoneData, phone: `+${changePhoneData.code}${changePhoneData.phone}` };
        const {code, ...newChangePhoneData} = mergePhone;

        await changePhoneApi(newChangePhoneData, token).then(()=>{
            setModal({...modal, status: false})
            message.success("Change mobile number request sent successfully!")
            setButtonLoading(false)
            getProfile()
        }).catch((error)=> {
            setButtonLoading(false)
            console.log(error)
        })
    }

    const updateNotificationPreferences = async (array) => {
        const data = {
            preferences: array
        }
        await updateNotificationPreferencesApi(data, token).then(()=>{
            message.success("Preferences updated successfully!")
        }).catch((error)=>{
            console.log(error)
        })
    }

    const preferenceNameFinder = (key) => {
        const find = preferencesData.find((x)=> x.key == key);
        if(find){
            return find.title;
        }
    }

    const changeSMSNotifications = (v) => {
        setSwitchToggle(v)
        const data = {
            smsNotificationEnabled: v
        }
        updateProfile(data)
    }

    const updateProfile = async (data) => {
        await updateProfileApi(data, token).then(()=>{
            message.success(translate('error_profile_updated'))
        }).catch((error)=> {
            console.log(error)
        })
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_profile')}</h1>
                <ProfileTabs />
                <h2>{translate('menu_profile')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('profile_information')}</h3>

                    {loading ? <Spin style={{ marginBottom: "55px" }} /> : profile?.isVerified ?
                        <div className={Style.AccountVerified}>
                            <img width={21} height={21} src={Verified} />
                            <span style={{ marginLeft: dir === "ltr" ? "5px" : null, display: "inline-block", marginRight: dir === "rtl" ? "5px" : null }}>{translate('profile_verified')}</span>
                        </div>
                        :
                        <div className={Style.AccountVerified}>
                            <img width={21} height={21} src={NotVerified} />
                            <span style={{ marginLeft: "5px", display: "inline-block" }}>{translate('profile_not_verified')}</span>
                        </div>
                    }

                    <Row gutter={{ xxl: 80, xl: 40, lg: 20, md: 20 }}>
                        <Col md={12} className="ant-col-full">
                            <div className={Style.AccountTable}>
                                <ul className="list-unstyled m-0 p-0">
                                    <li>
                                        <span>{translate('profile_name')}</span>
                                        <bdi>{profile?.firstName}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_last_name')}</span>
                                        <bdi>{profile?.lastName}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_client_id')}</span>
                                        <bdi>{profile?.id}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_date_birth')}</span>
                                        <bdi>{profile?.birthDate}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_password')}</span>
                                        <bdi>******</bdi>
                                        <div className={`${Style.CopyButton} cursor-pointer`}>
                                            <Button onClick={() => handleModal('password')} icon={<EditOutlined />} className="dark-green-button-border dark-green-color">
                                                <strong>{translate('change')}</strong>
                                            </Button>
                                        </div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_email')}</span>
                                        <bdi>{profile?.email}</bdi>
                                        <div className={`${Style.CopyButton} cursor-pointer`}>
                                            <Button onClick={() => handleModal('mail')} icon={<EditOutlined />} className="dark-green-button-border dark-green-color">
                                                <strong>{translate('change')}</strong>
                                            </Button>
                                        </div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_mobile_number')}</span>
                                        <bdi>{profile?.phone}</bdi>
                                        <div className={`${Style.CopyButton} cursor-pointer`}>
                                            <Button onClick={() => handleModal('phone')} icon={<EditOutlined />} className="dark-green-button-border dark-green-color">
                                                <strong>{translate('change')}</strong>
                                            </Button>
                                        </div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_communication_language')}</span>
                                        <bdi>{profile?.language}</bdi>
                                        <div className={`${Style.CopyButton} cursor-pointer`}>
                                            <Button onClick={() => handleModal('lang')} icon={<EditOutlined />} className="dark-green-button-border dark-green-color">
                                                <strong>{translate('change')}</strong>
                                            </Button>
                                        </div>
                                    </li>
                                    <li>
                                        <span>{translate('profile_country')}</span>
                                        <bdi>{profile?.country}</bdi>
                                        <div></div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={12} className="ant-col-full">
                            <div className={Style.AccountTable}>
                                <ul className="list-unstyled m-0 p-0">
                                    <li>
                                        <span>{translate('profile_notifications_preferences')}</span>
                                        <bdi></bdi>
                                    </li>
                                    <li>
                                        <Row>
                                            {Object.entries(preferences).map(([Key, Value], Index) => {
                                                return (
                                                    <Col md={12} key={Index}>
                                                        <div className={Style.Preferences}>
                                                            <Checkbox
                                                                value={Key}
                                                                checked={Value}
                                                                onChange={(e) => handleCheckboxChange(Key, e.target.checked)}
                                                            />
                                                            <span>{preferenceNameFinder(Key)}</span>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </li>
                                    <li>
                                        <span>{translate('profile_sms_notifications')}</span>
                                        <bdi></bdi>
                                        <div>
                                            <Switch checked={switchToggle}
                                                    checkedChildren={translate('profile_enabled')} unCheckedChildren={translate('profile_disabled')}
                                                    onChange={(e) => changeSMSNotifications(e)}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <span>{translate('menu_funds_wallets')}</span>
                                        <bdi></bdi>
                                    </li>
                                    <li>
                                        <span>{translate('menu_two_factor_authentication')}</span>
                                        <bdi>{profile?.twoFactorAuthEnabled ? translate('profile_enabled') : translate('profile_disabled')}</bdi>
                                        <div>
                                            <Button onClick={() => navigate('/profile/two-factor')} icon={<EditOutlined />} className="dark-green-button-border dark-green-color">
                                                <strong>{translate('setting')}</strong>
                                            </Button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal
                closable={false}
                width={679}
                title={[
                    <div key={1} className={`${Style.AccountModalHeader} ${Style.AccountModalHeaderSmall}`}>
                        {modal?.type === "password" ?
                            <span>{translate('modal_title_change_password')}</span>
                            : modal?.type === "mail" ?
                                <span>{translate('modal_title_change_mail')}</span>
                                : modal?.type === "phone" ?
                                    <span>{translate('modal_title_change_mobile')}</span>
                                    : modal?.type === "lang" ?
                                        <span>{translate('modal_title_change_language')}</span>
                                        : null}
                    </div>
                ]}
                footer={[
                    <div key={1} className="d-flex justify-content-center" style={{ marginBottom: "30px" }}>
                        <Button
                            shape="round"
                            onClick={() => (setModal({ ...modal, status: false }), setButtonLoading(false))}
                        >{translate('cancel')}</Button>
                        <Button
                            loading={buttonLoading}
                            shape="round"
                            className="dark-green-button white-color"
                            onClick={() => handleModalAction(modal?.type)}
                            disabled={modal?.type === "phone" && changePhoneStep === 1 ? (!changePhoneData?.phone || !changePhoneData?.pin ? true : false) : false}
                        >{translate('confirm')}</Button>
                    </div>
                ]}
                open={modal?.status}
                centered
                onCancel={() => setModal({ ...modal, status: false })}
            >
                {modal?.type === "password" ?
                    <div className={Style.ChangeForm}>
                        <div style={{ marginBottom: "10px" }}>
                            <label>{translate('current_password')}</label>
                            <Input.Password
                                onChange={(e) => setPasswordData({ ...passwordData, oldPassword: e.target.value })}
                                visibilityToggle={{
                                    visible: passwordVisible?.current,
                                    onVisibleChange: (e) => setPasswordVisible({ ...passwordVisible, current: e }),
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <label>{translate('new_password')}</label>
                            <Input.Password
                                onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                                visibilityToggle={{
                                    visible: passwordVisible?.new,
                                    onVisibleChange: (e) => setPasswordVisible({ ...passwordVisible, new: e }),
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <label>{translate('confirm_password')}</label>
                            <Input.Password
                                onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
                                visibilityToggle={{
                                    visible: passwordVisible?.confirm,
                                    onVisibleChange: (e) => setPasswordVisible({ ...passwordVisible, confirm: e }),
                                }}
                            />
                        </div>
                    </div>
                    : modal?.type === "mail" ?
                        <div className={Style.ChangeForm}>
                            {changeMailStep === 0 ?
                                <div className="d-md-flex">
                                    <label className="text-nowrap" style={dir === "ltr" ? { marginRight: "20px" } : {marginLeft: "20px"}}>{translate('send_pin_via')}</label>
                                    <Select
                                        className="w-100"
                                        defaultValue={1}
                                        options={[
                                            { key: 1, label: "Mail", value: 1 }
                                        ]}
                                    />
                                </div>
                                : changeMailStep === 1 ?
                                    <>
                                        <div style={{ marginBottom: "10px" }}>
                                            <label>{translate('pin')}</label>
                                            <Input style={{borderRadius: "25px"}} onChange={(e) => setChangeMailData({ ...changeMailData, pin: e.target.value })} />
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <label>{translate('current_email')}</label>
                                            <Input style={{borderRadius: "25px"}} disabled={true} value={profile?.email} />
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <label>{translate('new_email')}</label>
                                            <Input style={{borderRadius: "25px"}} onChange={(e) => setChangeMailData({ ...changeMailData, email: e.target.value })} />
                                        </div>
                                        <div style={{ marginBottom: "10px" }}>
                                            <label>{translate('reason')}</label>
                                            <TextArea onChange={(e) => setChangeMailData({ ...changeMailData, reason: e.target.value })} />
                                        </div>
                                    </>
                                    : null}
                        </div>
                        : modal?.type === "phone" ?
                            <div className={Style.ChangeForm}>
                                {changePhoneStep === 0 ?
                                    <div className="d-md-flex">
                                        <label className="text-nowrap" style={dir === "ltr" ? { marginRight: "20px" } : {marginLeft: "20px"}}>{translate('send_pin_via')}</label>
                                        <Select
                                            className="w-100"
                                            defaultValue={1}
                                            options={[
                                                { key: 1, label: "Mail", value: 1 }
                                            ]}
                                        />
                                    </div>
                                    : changePhoneStep === 1 ?
                                        <>
                                            <div style={{ marginBottom: "10px" }}>
                                                <label style={{ marginBottom: "5px", display: "block" }}>{translate('pin')}</label>
                                                <Input style={{borderRadius: "25px"}} onChange={(e) => setChangePhoneData({ ...changePhoneData, pin: e.target.value })} />
                                            </div>
                                            <div style={{ marginBottom: "10px" }}>
                                                <label style={{ marginBottom: "5px", display: "block" }}>{translate('current_phone')}</label>
                                                <Input style={{borderRadius: "25px"}} disabled={true} value={profile?.phone} />
                                            </div>
                                            <div style={{ marginBottom: "10px" }}>
                                                <label style={{ marginBottom: "5px", display: "block" }}>{translate('new_phone')}</label>
                                                <Input
                                                    type="number"
                                                    addonBefore={dir === "rtl" ? null :
                                                        <Select
                                                            defaultValue="98"
                                                            style={{width: "110px"}}
                                                            showSearch={true}
                                                            options={phoneCodes}
                                                            placeholder={translate('code')}
                                                            optionFilterProp="children"
                                                            filterOption={filterOption}
                                                            onChange={(ch) => setChangePhoneData({ ...changePhoneData, code: ch})}
                                                        />}
                                                    addonAfter={dir === "ltr" ? null :
                                                        <Select
                                                            defaultValue="98"
                                                            style={{width: "110px"}}
                                                            showSearch={true}
                                                            options={phoneCodes}
                                                            placeholder={translate('code')}
                                                            optionFilterProp="children"
                                                            filterOption={filterOption}
                                                            onChange={(ch) => setChangePhoneData({ ...changePhoneData, code: ch})}
                                                        />}
                                                    onChange={(e) => setChangePhoneData({ ...changePhoneData, phone: e.target.value })}
                                                    placeholder={translate('phone_number')}
                                                />
                                            </div>
                                            <div style={{ marginBottom: "10px" }}>
                                                <label style={{ marginBottom: "5px", display: "block" }}>{translate('reason')}</label>
                                                <TextArea onChange={(e) => setChangePhoneData({ ...changePhoneData, reason: e.target.value })} />
                                            </div>
                                        </>
                                        : null}
                            </div>
                            : modal?.type === "lang" ?
                                <div className={Style.ChangeForm}>
                                    <Select
                                        className="w-100"
                                        options={languages}
                                        defaultValue={profile?.language}
                                        onChange={(choice) => setChangeLanguageData({ language: choice })}
                                    />
                                </div>
                                : null}
            </Modal>
        </PanelLayout>
    )
}

export default Profile;